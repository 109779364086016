import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { Check, Star, Lock, Users, Rocket } from 'lucide-react';

interface UserAccount {
  membership: string;
}

interface MembershipSelectProps {
  userAccount?: UserAccount;
}

const MEMBERSHIP_TIERS = [
  {
    name: 'Non-member',
    selectname: 'Default',
    priceMonthly: 0,
    priceAnnual: 0,
    features: ['Read-only access', 'Basic community features', 'Limited marketplace browsing'],
    limitations: ['No event access', 'No posting privileges', 'No community participation'],
    recommended: false,
    icon: Lock,
    color: 'red',
  },
  {
    name: 'HGM Member',
    selectname: 'HGM Member',
    priceMonthly: 5,
    priceAnnual: 51, // Reflects 15% savings
    features: ['Full community access', 'Event participation', 'Marketplace access', 'Forum posting'],
    recommended: false,
    icon: Users,
    color: 'green',
  },
  {
    name: 'Advocate',
    selectname: 'Advocate Member',
    priceMonthly: 200, // Lifetime price
    priceAnnual: 200, // Lifetime price
    features: [
      'Everything in Member',
      'Special community badge',
      'Early access to features',
      'Lifetime Membership',
    ],
    recommended: true,
    icon: Star,
    color: 'green',
    limitedSeats: true, // Marks Advocate Membership as limited
  },
  {
    name: 'Pro Member',
    selectname: 'Pro Member',
    priceMonthly: 25,
    priceAnnual: 240, // Reflects 20% annual savings
    features: [
      'Everything in Member',
      'Pro features access',
      'Event planning tools',
      'Advanced analytics',
      'Pro-support',
    ],
    recommended: false,
    icon: Rocket,
    color: 'blue',
    locked: true, // Pro membership is locked until 2026
  },
];

const MembershipSelect: React.FC<MembershipSelectProps> = ({ userAccount }) => {
  const [currentMembership, setCurrentMembership] = useState<string>('Non-member');
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'annual'>('monthly');
  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (userAccount) {
      const foundMembership = MEMBERSHIP_TIERS.find((tier) => tier.name === userAccount.membership);
      setCurrentMembership(foundMembership ? foundMembership.name : 'Non-member');
    }
  }, [userAccount]);

  const currentMembershipDetails =
    MEMBERSHIP_TIERS.find((tier) => tier.name === currentMembership) || MEMBERSHIP_TIERS[0];

  const toggleBillingCycle = () => {
    setBillingCycle((prev) => (prev === 'monthly' ? 'annual' : 'monthly'));
  };

  const handleSelectMembership = (tier: typeof MEMBERSHIP_TIERS[0]) => {
    if (tier.locked) {
      setPopupMessage('This membership is locked until 2026. Click "Notify Me" to show interest.');
      return;
    }

    if (!userAccount) {
      if (tier.name !== 'Non-member') {
        navigate('/login', { state: { from: '/membership-select' } });
      }
    } else {
      if (currentMembership === tier.name) {
        setPopupMessage('You selected your current membership');
      } else if (currentMembership === 'Non-member') {
        navigate('/checkout', { state: { selectedTier: tier.name } });
      } else {
        navigate('/checkout', { state: { selectedTier: tier.name } });
      }
    }
  };

  const calculateDiscount = (monthly: number, annual: number) => {
    if (monthly && annual) {
      const annualEquivalent = monthly * 12;
      const discount = ((annualEquivalent - annual) / annualEquivalent) * 100;
      return Math.round(discount);
    }
    return 0;
  };

  return (
    <>
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 gradient-bg rounded-xl overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-5xl font-bold text-white mb-4">
              Support Your Community & Grow
            </h1>
            <p className="text-2xl text-white">
              Select the plan that best fits your needs
            </p>

            {userAccount && (
              <div
                className={`mt-6 inline-flex items-center space-x-3 py-4 px-6 rounded-lg text-white font-medium bg-${currentMembershipDetails.color}-600`}
              >
                <currentMembershipDetails.icon className="h-6 w-6" />
                <span>Current Membership: {currentMembership}</span>
              </div>
            )}
          </div>

          <div className="mt-6 mb-8">
            <motion.div
              className="flex justify-center items-center space-x-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <span className="text-white text-lg">Monthly</span>
              <label className="relative inline-block w-16 h-8">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={billingCycle === 'annual'}
                  onChange={toggleBillingCycle}
                />
                <div className="absolute inset-0 bg-slate-400 rounded-full peer-checked:bg-green-500 transition duration-300"></div>
                <div className="absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform peer-checked:translate-x-8 transition duration-300"></div>
              </label>
              <span className="text-white text-lg">Annual</span>
            </motion.div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
            {MEMBERSHIP_TIERS.map((tier) => (
              <motion.div
                key={tier.name}
                whileHover={{ scale: 1.05 }}
                className={`relative bg-white rounded-2xl shadow-xl overflow-hidden border-2 ${
                  tier.recommended ? 'border-emerald-500' : 'border-stone-300'
                }`}
              >
                {billingCycle === 'annual' && tier.priceMonthly > 0 && tier.name !== 'Advocate' && (
                  <div className="absolute top-0 left-0 bg-green-600 text-white px-4 py-1 rounded-tr-lg rounded-bl-lg">
                    Save {calculateDiscount(tier.priceMonthly, tier.priceAnnual)}% Annually!
                  </div>
                )}

                {tier.name === 'Advocate' && tier.limitedSeats && (
                  <div className="absolute top-0 left-0 bg-indigo-600 text-white px-4 py-1 rounded-tr-lg rounded-bl-lg">
                    Limited Seats!
                  </div>
                )}

                {tier.name === 'Pro Member' && (
                  <div className="absolute top-0 right-0 bg-blue-600 text-white px-4 py-1 rounded-bl-lg">
                    Coming 2026!
                  </div>
                )}

                {tier.recommended && (
                  <div className="absolute top-0 right-0 bg-green-800 text-white px-4 py-1 rounded-bl-lg">
                    Recommended
                  </div>
                )}

                <div className="p-8 pb-20 mt-4"> {/* Added margin here */}
                  <div className="flex items-center space-x-3 mb-4">
                    <tier.icon className={`h-8 w-8 text-${tier.color}-500`} />
                    <h2 className="text-2xl font-bold">{tier.name}</h2>
                  </div>

                  <div className="mb-6">
                    <button
                      onClick={() => handleSelectMembership(tier)}
                      className={`w-full flex items-center justify-center px-6 py-3 rounded-lg text-white font-semibold ${
                        tier.locked ? 'bg-gray-400 cursor-not-allowed' : `bg-${tier.color}-600 hover:bg-${tier.color}-700`
                      }`}
                      disabled={tier.locked}
                    >
                      <tier.icon className="h-5 w-5 mr-2" />
                      {tier.selectname}
                    </button>
                  </div>

                  {tier.locked && (
                    <button
                      onClick={() => setPopupMessage('Thank you for your interest!')}
                      className="w-full flex items-center justify-center px-6 py-3 rounded-lg text-white font-semibold bg-blue-600 hover:bg-blue-700"
                    >
                      Notify Me
                    </button>
                  )}

                  <ul className="space-y-4">
                    {tier.features.map((feature, index) => (
                      <li key={index} className="flex items-center space-x-2">
                        <Check className={`h-6 w-6 text-${tier.color}-500`} />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="absolute bottom-0 left-0 w-full bg-green-100 p-5 text-center rounded-b-4xl">
                  <p className="text-3xl font-bold text-gray-800">
                    {tier.name === 'Advocate'
                      ? `$200 Lifetime`
                      : billingCycle === 'monthly'
                      ? `$${tier.priceMonthly} / Month`
                      : `$${tier.priceAnnual} / Year`}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {popupMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
            <p className="text-lg font-semibold mb-4">{popupMessage}</p>
            <button
              className="mt-4 px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
              onClick={() => setPopupMessage(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <style>
        {`
          @keyframes gradientMove {
            0% {
              background-position: 50% 100%;
            }
            50% {
              background-position: 50% 0%;
            }
            100% {
              background-position: 50% 100%;
            }
          }

          .gradient-bg {
            background: linear-gradient(
              180deg,
              #2d3748, /* Darker color */
              #14532d, /* Medium green */
              #22c55e  /* Light green */
            );
            background-size: 300% 300%;
            animation: gradientMove 60s infinite ease-in-out;
          }
        `}
      </style>
    </>
  );
};

export default MembershipSelect;
