import React from 'react';
import { motion } from 'framer-motion';
import { Home, Sun, Thermometer, MessageSquare, ThumbsUp, Settings } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Vertical Farm Setup in Spare Room',
    author: 'UrbanGrower',
    image: 'https://images.unsplash.com/photo-1585619203238-a51e6eb12dab?auto=format&fit=crop&q=80&w=600',
    content: 'My complete guide to setting up a vertical growing system in limited space...',
    likes: 67,
    comments: 31,
    tags: ['Vertical', 'LED', 'Space Saving']
  },
  // ... other posts
];

export function IndoorFarm() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Rest of your existing JSX */}
    </motion.div>
  );
}