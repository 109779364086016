import React from 'react';
import { motion } from 'framer-motion';
import { Apple, AlertTriangle, MessageSquare, ThumbsUp, Map } from 'lucide-react';

const SEASONAL_GUIDE = [
  {
    season: 'Spring',
    items: ['Ramps', 'Morels', 'Dandelions', 'Fiddleheads']
  },
  {
    season: 'Summer',
    items: ['Berries', 'Chanterelles', 'Purslane', 'Lambs Quarters']
  },
  {
    season: 'Fall',
    items: ['Nuts', 'Hen of the Woods', 'Rose Hips', 'Autumn Olives']
  },
  {
    season: 'Winter',
    items: ['Pine Needles', 'Birch Bark', 'Winter Cress', 'Juniper Berries']
  }
];

export function Foraging() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Hero Section */}
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1523348837708-15d4a09cfac2?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Foraging Community</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Learn about wild edibles and share your foraging experiences
            </p>
            <button className="bg-white/10 backdrop-blur-sm text-white px-6 py-3 rounded-lg hover:bg-white/20 transition-colors border-2 border-green-100/20">
              Share Discovery
            </button>
          </div>
        </div>
      </div>

      {/* Seasonal Guide */}
      <div className="grid gap-6">
        {SEASONAL_GUIDE.map((season) => (
          <motion.div
            key={season.season}
            whileHover={{ scale: 1.02 }}
            className="bg-white rounded-lg shadow-md p-6"
          >
            <h2 className="text-xl font-semibold mb-4">{season.season}</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {season.items.map((item) => (
                <div key={item} className="bg-green-50 p-3 rounded-lg">
                  <span className="text-green-800">{item}</span>
                </div>
              ))}
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}