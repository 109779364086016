import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, Users, AlertTriangle, Settings, Database, FileText, Activity, TrendingUp, Bell } from 'lucide-react';
import { Link } from 'react-router-dom';

// Mock data for demonstration
const RECENT_ACTIVITIES = [
  { id: 1, type: 'security', message: 'Unusual login attempt blocked', time: '2 mins ago' },
  { id: 2, type: 'user', message: 'New vendor account created', time: '15 mins ago' },
  { id: 3, type: 'system', message: 'System backup completed', time: '1 hour ago' },
];

const SYSTEM_METRICS = {
  uptime: '99.9%',
  activeUsers: 1234,
  pendingReports: 5,
  systemLoad: '23%'
};

const ACTION_ITEMS = [
  { id: 1, title: 'Review Vendor Applications', count: 8, priority: 'high' },
  { id: 2, title: 'Pending User Reports', count: 5, priority: 'medium' },
  { id: 3, title: 'System Updates Available', count: 3, priority: 'low' },
];

export function AdminHome() {
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gray-50"
    >
      {/* Hero Section */}
      <div className="relative h-[300px] text-center overflow-hidden rounded-lg mb-8">
        <div 
          className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/70 to-pink-800/80" />
        
        <div className="relative z-10 flex flex-col items-center justify-center h-full px-4">
          <h1 className="text-5xl font-bold mb-6 text-white drop-shadow-lg">
            Admin Dashboard
          </h1>
          <div className="flex flex-wrap justify-center gap-4">
            <div className="bg-white/10 backdrop-blur-sm px-6 py-3 rounded-lg">
              <p className="text-2xl font-bold text-white">{SYSTEM_METRICS.activeUsers}</p>
              <p className="text-sm text-white/80">Active Users</p>
            </div>
            <div className="bg-white/10 backdrop-blur-sm px-6 py-3 rounded-lg">
              <p className="text-2xl font-bold text-white">{SYSTEM_METRICS.uptime}</p>
              <p className="text-sm text-white/80">System Uptime</p>
            </div>
            <div className="bg-white/10 backdrop-blur-sm px-6 py-3 rounded-lg">
              <p className="text-2xl font-bold text-white">{SYSTEM_METRICS.pendingReports}</p>
              <p className="text-sm text-white/80">Pending Reports</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 pb-8">
        <div className="grid lg:grid-cols-3 gap-8">
          {/* Main Content - First Two Columns */}
          <div className="lg:col-span-2 space-y-8">
            {/* Action Items */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">Action Items</h2>
                <Link to="/admin/tasks" className="text-purple-600 hover:text-purple-700">
                  View All
                </Link>
              </div>
              <div className="space-y-4">
                {ACTION_ITEMS.map(item => (
                  <div 
                    key={item.id}
                    className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`w-2 h-2 rounded-full ${
                        item.priority === 'high' ? 'bg-red-500' :
                        item.priority === 'medium' ? 'bg-yellow-500' :
                        'bg-green-500'
                      }`} />
                      <span className="font-medium">{item.title}</span>
                    </div>
                    <span className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm">
                      {item.count}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* System Metrics */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-6">System Metrics</h2>
              <div className="grid grid-cols-2 gap-6">
                <div className="p-4 bg-purple-50 rounded-lg">
                  <div className="flex items-center justify-between">
                    <Activity className="h-5 w-5 text-purple-600" />
                    <span className="text-sm text-purple-600">Real-time</span>
                  </div>
                  <p className="text-2xl font-bold mt-2">{SYSTEM_METRICS.systemLoad}</p>
                  <p className="text-sm text-gray-600">System Load</p>
                </div>
                <div className="p-4 bg-blue-50 rounded-lg">
                  <div className="flex items-center justify-between">
                    <TrendingUp className="h-5 w-5 text-blue-600" />
                    <span className="text-sm text-blue-600">Today</span>
                  </div>
                  <p className="text-2xl font-bold mt-2">2,345</p>
                  <p className="text-sm text-gray-600">API Requests</p>
                </div>
              </div>
            </div>
          </div>

          {/* Sidebar - Third Column */}
          <div className="space-y-8">
            {/* Recent Activity */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-6">Recent Activity</h2>
              <div className="space-y-4">
                {RECENT_ACTIVITIES.map(activity => (
                  <div key={activity.id} className="flex items-start space-x-3">
                    <div className={`p-2 rounded-lg ${
                      activity.type === 'security' ? 'bg-red-100' :
                      activity.type === 'user' ? 'bg-blue-100' :
                      'bg-green-100'
                    }`}>
                      {activity.type === 'security' ? <Shield className="h-4 w-4 text-red-600" /> :
                       activity.type === 'user' ? <Users className="h-4 w-4 text-blue-600" /> :
                       <Database className="h-4 w-4 text-green-600" />}
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">{activity.message}</p>
                      <p className="text-xs text-gray-400">{activity.time}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Quick Actions */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-6">Quick Actions</h2>
              <div className="space-y-3">
                <button className="w-full flex items-center justify-between p-3 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors">
                  <span className="font-medium text-purple-700">System Backup</span>
                  <Database className="h-4 w-4 text-purple-600" />
                </button>
                <button className="w-full flex items-center justify-between p-3 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors">
                  <span className="font-medium text-purple-700">User Management</span>
                  <Users className="h-4 w-4 text-purple-600" />
                </button>
                <button className="w-full flex items-center justify-between p-3 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors">
                  <span className="font-medium text-purple-700">Security Logs</span>
                  <Shield className="h-4 w-4 text-purple-600" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}