import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './useAuth';
import { MOCK_CONVERSATIONS } from '../lib/mockData';

export interface Conversation {
  id: string;
  participants: string[];
  lastMessage: string;
  timestamp: string;
  unreadCount: number;
}

export function useConversations() {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Use mock data if Firebase is not initialized or user is not authenticated
    if (!db || !currentUser) {
      setConversations(MOCK_CONVERSATIONS);
      setLoading(false);
      return;
    }

    try {
      const q = query(
        collection(db, 'conversations'),
        where('participants', 'array-contains', currentUser.uid),
        orderBy('lastMessageAt', 'desc')
      );

      const unsubscribe = onSnapshot(q,
        (snapshot) => {
          const newConversations = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              participants: data.participants,
              lastMessage: data.lastMessage,
              timestamp: formatTimestamp(data.lastMessageAt),
              unreadCount: data.unreadCount?.[currentUser.uid] || 0
            };
          });
          setConversations(newConversations);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
          // Fallback to mock data on error
          setConversations(MOCK_CONVERSATIONS);
        }
      );

      return () => unsubscribe();
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      // Fallback to mock data on error
      setConversations(MOCK_CONVERSATIONS);
    }
  }, [currentUser]);

  return { conversations, loading, error };
}

function formatTimestamp(timestamp: Timestamp): string {
  const now = new Date();
  const date = timestamp.toDate();
  const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));

  if (diffInMinutes < 1) return 'Just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
  if (diffInMinutes < 10080) return `${Math.floor(diffInMinutes / 1440)}d ago`;
  
  return date.toLocaleDateString();
}