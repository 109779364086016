import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Utensils, MessageSquare, ThumbsUp, BookOpen } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Seasonal Vegetable Stir-Fry',
    author: 'GardenChef',
    image: 'https://images.unsplash.com/photo-1540420773420-3366772f4999?auto=format&fit=crop&q=80&w=600',
    content: 'Quick and easy recipe using fresh garden vegetables...',
    likes: 95,
    comments: 42,
    tags: ['Recipe', 'Vegetables', 'Quick']
  },
  {
    id: 2,
    title: 'Preserving Summer Tomatoes',
    author: 'PreservePro',
    image: 'https://images.unsplash.com/photo-1592924357228-91a4daadcfea?auto=format&fit=crop&q=80&w=600',
    content: 'Methods for preserving your tomato harvest...',
    likes: 73,
    comments: 31,
    tags: ['Preserving', 'Tomatoes', 'Storage']
  }
];

const COOKING_TIPS = [
  {
    category: 'Preparation',
    tips: ['Wash produce thoroughly', 'Prep ingredients before cooking', 'Keep knives sharp', 'Use fresh herbs']
  },
  {
    category: 'Techniques',
    tips: ['Control heat properly', 'Season in layers', 'Rest meat after cooking', 'Toast spices']
  },
  {
    category: 'Storage',
    tips: ['Store herbs in water', 'Keep root vegetables cool', 'Use airtight containers', 'Label everything']
  }
];

export function Cooking() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1540420773420-3366772f4999?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Garden to Table Cooking</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Share recipes and cooking tips for your homegrown produce
            </p>
            <button className="bg-white/10 backdrop-blur-sm text-white px-6 py-3 rounded-lg hover:bg-white/20 transition-colors border-2 border-green-100/20">
              Share Recipe
            </button>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <div className="col-span-2 space-y-6">
          {MOCK_POSTS.map((post) => (
            <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2 text-green-700">{post.title}</h2>
                <p className="text-gray-600 mb-4">{post.content}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {post.tags.map((tag) => (
                    <span key={tag} className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex items-center justify-between text-gray-600">
                  <span>Posted by {post.author}</span>
                  <div className="flex items-center space-x-4">
                    <button className="flex items-center space-x-1">
                      <ThumbsUp className="h-4 w-4" />
                      <span>{post.likes}</span>
                    </button>
                    <button className="flex items-center space-x-1">
                      <MessageSquare className="h-4 w-4" />
                      <span>{post.comments}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Cooking Tips</h2>
            <div className="space-y-4">
              {COOKING_TIPS.map((section) => (
                <div key={section.category} className="p-3 hover:bg-green-50 rounded-lg">
                  <h3 className="font-medium flex items-center space-x-2">
                    <Utensils className="h-4 w-4 text-green-600" />
                    <span>{section.category}</span>
                  </h3>
                  <ul className="mt-2 ml-6 space-y-1">
                    {section.tips.map((tip, index) => (
                      <li key={index} className="text-gray-600 text-sm">{tip}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Recipe Collections</h2>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <Cookie className="h-5 w-5 text-green-600 mt-1" />
                <div>
                  <h3 className="font-medium">Seasonal Recipes</h3>
                  <p className="text-sm text-gray-600">Cook with what's currently in season</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <BookOpen className="h-5 w-5 text-green-600 mt-1" />
                <div>
                  <h3 className="font-medium">Preservation Guide</h3>
                  <p className="text-sm text-gray-600">Methods for storing your harvest</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}