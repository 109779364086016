import React from 'react';
import { User } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useMessages } from '../../hooks/useMessages';

interface MessagePreviewProps {
  isBottom: boolean;
  onMouseLeave: () => void;
  mode?: 'default' | 'pro' | 'admin';
}

export function MessagePreview({
  isBottom,
  onMouseLeave,
  mode = 'default',
}: MessagePreviewProps) {
  const { messages, loading } = useMessages();

  const getBgClass = () => {
    switch (mode) {
      case 'pro':
        return 'bg-gradient-to-r from-blue-900 to-green-900';
      case 'admin':
        return 'bg-gradient-to-r from-gray-900 to-gray-900';
      default:
        return 'bg-gradient-to-r from-stone-150 via-stone-850 to-green-900';
    }
  };

  return (
    <motion.div
      className={`absolute right-0 w-[300px] rounded-xl shadow-xl overflow-hidden ${getBgClass()}`}
      initial={{ opacity: 0, x: 50, y: isBottom ? 10 : -10 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, x: 50, y: isBottom ? 10 : -10 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      onMouseLeave={onMouseLeave}
      style={{
        [isBottom ? 'bottom' : 'top']: 'calc(100% + 0.75rem)',
      }}
    >
      <div className="p-4">
        <h3 className="text-sm font-semibold text-white/90 mb-4">
          Recent Messages
        </h3>
        {loading ? (
          <div className="flex justify-center py-4">
            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
          </div>
        ) : (
          <div className="space-y-2">
            {messages?.map((message) => (
              <Link
                key={message.id}
                to={`/messages/${message.conversationId}`}
                className="flex items-start space-x-3 px-2 py-1.5 rounded hover:bg-white/10 transition-colors"
              >
                <div className="bg-white/10 p-2 rounded-full">
                  <User className="h-4 w-4 text-white/80" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="font-medium text-sm text-white/90">
                    {message.sender}
                  </p>
                  <p className="text-sm text-white/70 truncate">
                    {message.content}
                  </p>
                  <p className="text-xs text-white/50 mt-1">
                    {message.timestamp}
                  </p>
                </div>
                {message.unread && (
                  <div className="w-2 h-2 bg-green-500 rounded-full mt-2"></div>
                )}
              </Link>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
}
