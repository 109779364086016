import React, { useRef, useState } from 'react';
import { Camera, Loader } from 'lucide-react';
import { ImageCropper } from './ImageCropper';

interface ImageUploaderProps {
  currentImage?: string | null;
  onImageSelect: (blob: Blob) => Promise<void>;
  loading?: boolean;
}

export function ImageUploader({ currentImage, onImageSelect, loading }: ImageUploaderProps) {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Reset states
    setError(null);
    setUploadProgress(0);

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('File size must be less than 5MB');
      return;
    }

    // Check file type
    if (!file.type.startsWith('image/')) {
      setError('File must be an image');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = async (croppedImage: Blob) => {
    try {
      setUploadProgress(0);
      await onImageSelect(croppedImage);
      setSelectedImage(null);
      setUploadProgress(100);
      
      // Reset progress after a delay
      setTimeout(() => setUploadProgress(0), 2000);
    } catch (error) {
      setError('Failed to upload image. Please try again.');
      console.error('Error uploading image:', error);
    }
  };

  return (
    <div className="relative">
      <div className="relative">
        <div className="bg-green-100 w-24 h-24 rounded-full flex items-center justify-center overflow-hidden relative">
          {currentImage ? (
            <img
              src={currentImage}
              alt="Profile"
              className="w-full h-full object-cover"
              key={currentImage} // Force re-render when image changes
            />
          ) : (
            <Camera className="h-12 w-12 text-green-600" />
          )}
          
          {/* Upload Progress Overlay */}
          {(loading || uploadProgress > 0) && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <div className="w-16 h-16 relative">
                <svg className="w-full h-full" viewBox="0 0 100 100">
                  <circle
                    className="text-gray-300"
                    strokeWidth="8"
                    stroke="currentColor"
                    fill="transparent"
                    r="42"
                    cx="50"
                    cy="50"
                  />
                  <circle
                    className="text-green-500"
                    strokeWidth="8"
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="42"
                    cx="50"
                    cy="50"
                    strokeDasharray="264"
                    strokeDashoffset={264 - (uploadProgress / 100) * 264}
                  />
                </svg>
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-white text-sm font-medium">
                    {uploadProgress}%
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={loading || uploadProgress > 0}
          className="absolute bottom-0 right-0 bg-white p-2 rounded-full shadow-md hover:bg-gray-50 disabled:opacity-50"
        >
          {loading ? (
            <Loader className="h-4 w-4 text-gray-600 animate-spin" />
          ) : (
            <Camera className="h-4 w-4 text-gray-600" />
          )}
        </button>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          className="hidden"
        />
      </div>

      {error && (
        <div className="mt-2 text-sm text-red-600 absolute -bottom-8 left-0 right-0 text-center">
          {error}
        </div>
      )}

      {selectedImage && (
        <ImageCropper
          imageUrl={selectedImage}
          onCropComplete={handleCropComplete}
          onCancel={() => {
            setSelectedImage(null);
            setError(null);
          }}
          aspectRatio={1}
        />
      )}
    </div>
  );
}