import React from 'react';
import { User, Paperclip, Send, MessageCircle, Loader } from 'lucide-react';
import { Message } from '../../types/messages';

interface ChatAreaProps {
  messages: Message[];
  loading: boolean;
  newMessage: string;
  onNewMessageChange: (message: string) => void;
  onSendMessage: () => void;
}

export function ChatArea({
  messages,
  loading,
  newMessage,
  onNewMessageChange,
  onSendMessage,
}: ChatAreaProps) {
  if (loading) {
    return (
      <div className="col-span-8 flex items-center justify-center">
        <Loader className="h-8 w-8 animate-spin text-emerald-600" />
      </div>
    );
  }

  if (!messages.length) {
    return (
      <div className="col-span-8 flex items-center justify-center text-gray-500">
        <div className="text-center">
          <MessageCircle className="h-12 w-12 mx-auto mb-2" />
          <p>Select a conversation to start messaging</p>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-8 flex flex-col">
      <div className="p-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="bg-green-100 p-2 rounded-full">
            <User className="h-6 w-6 text-green-600" />
          </div>
          <div>
            <h2 className="font-medium">{messages[0]?.sender}</h2>
            <p className="text-sm text-gray-500">Online</p>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${
              message.isOwn ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[70%] ${
                message.isOwn
                  ? 'bg-green-600 text-white rounded-l-lg rounded-br-lg'
                  : 'bg-gray-100 rounded-r-lg rounded-bl-lg'
              } p-3`}
            >
              <p>{message.content}</p>
              <p
                className={`text-xs mt-1 ${
                  message.isOwn ? 'text-green-100' : 'text-gray-500'
                }`}
              >
                {message.timestamp}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="p-4 border-t">
        <div className="flex items-center space-x-2">
          <button className="p-2 hover:bg-gray-100 rounded-lg">
            <Paperclip className="h-5 w-5 text-gray-500" />
          </button>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => onNewMessageChange(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
            onKeyPress={(e) => e.key === 'Enter' && onSendMessage()}
          />
          <button
            onClick={onSendMessage}
            className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}
