import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Routes, Route } from 'react-router-dom';
import { Search, Leaf, Sprout, MessageSquare, Users, Filter, Flower2 } from 'lucide-react';
import { Gardens } from './discussions/Gardens';
import { JobBoard } from './discussions/JobBoard';
import { Trees } from './discussions/id/Trees';
import { Plants } from './discussions/id/Plants';
import { Flowers } from './discussions/id/Flowers';
import { Permaculture } from './discussions/grow/Permaculture';
import { Hydroponic } from './discussions/grow/Hydroponic';
import { IndoorFarm } from './discussions/grow/IndoorFarm';
import { BeeKeeping } from './discussions/grow/BeeKeeping';
import { Foraging } from './discussions/live/Foraging';
import { ZeroWaste } from './discussions/live/ZeroWaste';
import { Compost } from './discussions/live/Compost';
import { Cooking } from './discussions/live/Cooking';
import { Fermented } from './discussions/live/Fermented';
import { FarmShare } from './discussions/csa/FarmShare';
import { DiscussionBoard } from './discussions/DiscussionBoard';




export function Discussions() {
  return (
    <Routes>
      <Route index element={<DiscussionBoard />} />
      <Route path="jobs" element={<JobBoard />} />
      <Route path="gardens" element={<Gardens />} />
      <Route path="id/trees" element={<Trees />} />
      <Route path="id/plants" element={<Plants />} />
      <Route path="id/flowers" element={<Flowers />} />
      <Route path="grow/permaculture" element={<Permaculture />} />
      <Route path="grow/hydroponic" element={<Hydroponic />} />
      <Route path="grow/indoor" element={<IndoorFarm />} />
      <Route path="grow/bees" element={<BeeKeeping />} />
      <Route path="live/foraging" element={<Foraging />} />
      <Route path="live/zero-waste" element={<ZeroWaste />} />
      <Route path="live/compost" element={<Compost />} />
      <Route path="live/cooking" element={<Cooking />} />
      <Route path="live/fermented" element={<Fermented />} />
      <Route path="csa/farm-share" element={<FarmShare />} />
    </Routes>
  );
}