import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar, MapPin, Clock, DollarSign, Users, Filter } from 'lucide-react';

const MOCK_EVENTS = [
  {
    id: 1,
    title: 'Organic Farming Workshop',
    date: '2024-03-20',
    time: '10:00 AM',
    location: 'Green Fields Farm',
    price: 25,
    capacity: 20,
    registered: 12,
    category: 'Workshop',
    description: 'Learn the fundamentals of organic farming practices.',
    rules: [
      'No children under 12',
      'Wear appropriate footwear',
      'Bring your own water bottle'
    ]
  },
  {
    id: 2,
    title: 'Community Garden Meetup',
    date: '2024-03-22',
    time: '2:00 PM',
    location: 'Downtown Community Garden',
    price: 0,
    capacity: 30,
    registered: 15,
    category: 'Meetup',
    description: 'Monthly gathering of local gardeners to share tips and experiences.',
    rules: [
      'Open to all skill levels',
      'Bring gardening tools if possible',
      'Weather permitting'
    ]
  },
  {
    id: 3,
    title: 'Sustainable Living Workshop',
    date: '2024-03-25',
    time: '1:00 PM',
    location: 'Eco Center',
    price: 35,
    capacity: 25,
    registered: 18,
    category: 'Workshop',
    description: 'Comprehensive workshop on sustainable living practices.',
    rules: [
      'Bring a notebook',
      'All materials provided',
      'Registration required'
    ]
  }
];

export function Events() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1464226184884-fa280b87c399?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Community Events</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Join workshops, meetups, and community gatherings
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search events..."
                  className="w-full pl-4 pr-4 py-3 border-2 border-green-100/20 bg-white/10 backdrop-blur-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-white placeholder-green-100/70"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <select
                className="px-4 py-3 border-2 border-green-100/20 bg-white/10 backdrop-blur-sm rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-green-500"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="all" className="text-gray-800">All Categories</option>
                <option value="workshop" className="text-gray-800">Workshops</option>
                <option value="meetup" className="text-gray-800">Meetups</option>
                <option value="sale" className="text-gray-800">Sales</option>
              </select>
              <button className="bg-white/10 backdrop-blur-sm text-white px-6 py-3 rounded-lg hover:bg-white/20 transition-colors border-2 border-green-100/20">
                Create Event
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <div className="col-span-2">
          <div className="space-y-4">
            {MOCK_EVENTS.map((event) => (
              <motion.div 
                key={event.id} 
                className="bg-white p-6 rounded-lg shadow-md"
                whileHover={{ scale: 1.02 }}
              >
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-xl font-semibold">{event.title}</h3>
                  <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                    {event.category}
                  </span>
                </div>
                <p className="text-gray-600 mb-4">{event.description}</p>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Calendar className="h-5 w-5" />
                    <span>{event.date}</span>
                  </div>
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Clock className="h-5 w-5" />
                    <span>{event.time}</span>
                  </div>
                  <div className="flex items-center space-x-2 text-gray-600">
                    <MapPin className="h-5 w-5" />
                    <span>{event.location}</span>
                  </div>
                  <div className="flex items-center space-x-2 text-gray-600">
                    <DollarSign className="h-5 w-5" />
                    <span>${event.price}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Users className="h-5 w-5" />
                    <span>{event.registered}/{event.capacity} registered</span>
                  </div>
                  <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
                    Register
                  </button>
                </div>
                <div className="mt-4 pt-4 border-t">
                  <h4 className="font-medium mb-2">Event Rules:</h4>
                  <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                    {event.rules.map((rule, index) => (
                      <li key={index}>{rule}</li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">My Events</h2>
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="text-gray-600">No registered events</p>
                <button className="mt-2 text-green-600 text-sm hover:text-green-700">
                  Browse events →
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Event Categories</h2>
            <div className="space-y-2">
              <button className="w-full text-left px-3 py-2 rounded-lg hover:bg-green-50 flex items-center justify-between">
                <span>Workshops</span>
                <span className="text-gray-500">(12)</span>
              </button>
              <button className="w-full text-left px-3 py-2 rounded-lg hover:bg-green-50 flex items-center justify-between">
                <span>Meetups</span>
                <span className="text-gray-500">(5)</span>
              </button>
              <button className="w-full text-left px-3 py-2 rounded-lg hover:bg-green-50 flex items-center justify-between">
                <span>Sales</span>
                <span className="text-gray-500">(8)</span>
              </button>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Calendar</h2>
            <div className="text-center text-gray-600">
              <p>Calendar component coming soon</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}