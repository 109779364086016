import React from 'react';
import { motion } from 'framer-motion';
import { Bug, AlertTriangle, MessageSquare, ThumbsUp, Flower2 } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Starting Your First Hive',
    author: 'BeeExpert',
    image: 'https://images.unsplash.com/photo-1587236317816-3ad3d4d5299f?auto=format&fit=crop&q=80&w=600',
    content: 'Complete guide for beginners on setting up their first beehive...',
    likes: 89,
    comments: 42,
    tags: ['Beginner', 'Setup', 'Guide']
  },
  {
    id: 2,
    title: 'Seasonal Hive Management',
    author: 'HoneyMaker',
    image: 'https://images.unsplash.com/photo-1544586784-b9ee5a0c8134?auto=format&fit=crop&q=80&w=600',
    content: 'How to care for your bees throughout the changing seasons...',
    likes: 56,
    comments: 27,
    tags: ['Seasonal', 'Management', 'Tips']
  }
];

export function BeeKeeping() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Hero Section */}
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1587236317816-3ad3d4d5299f?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Beekeeping Community</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Connect with fellow beekeepers and learn about sustainable apiculture
            </p>
            <button className="bg-white/10 backdrop-blur-sm text-white px-6 py-3 rounded-lg hover:bg-white/20 transition-colors border-2 border-green-100/20">
              Share Your Experience
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="grid md:grid-cols-3 gap-6">
        {MOCK_POSTS.map((post) => (
          <motion.div
            key={post.id}
            whileHover={{ scale: 1.02 }}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
              <p className="text-gray-600 mb-4">{post.content}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {post.tags.map((tag) => (
                  <span key={tag} className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex items-center justify-between text-gray-600">
                <span>Posted by {post.author}</span>
                <div className="flex items-center space-x-4">
                  <button className="flex items-center space-x-1">
                    <ThumbsUp className="h-4 w-4" />
                    <span>{post.likes}</span>
                  </button>
                  <button className="flex items-center space-x-1">
                    <MessageSquare className="h-4 w-4" />
                    <span>{post.comments}</span>
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}