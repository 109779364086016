import React, { createContext, useContext, useState } from 'react';
import * as LucideIcons from 'lucide-react';

type IconName = keyof typeof LucideIcons;
type IconMapping = Record<string, IconName>;

interface IconContextType {
  iconMapping: IconMapping;
  updateIcon: (key: string, newIcon: IconName) => void;
  isEditMode: boolean;
  setEditMode: (mode: boolean) => void;
}

const IconContext = createContext<IconContextType | undefined>(undefined);

export function IconProvider({ children }: { children: React.ReactNode }) {
  const [iconMapping, setIconMapping] = useState<IconMapping>(() => {
    const saved = localStorage.getItem('iconMapping');
    return saved ? JSON.parse(saved) : {};
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const updateIcon = (key: string, newIcon: IconName) => {
    const newMapping = { ...iconMapping, [key]: newIcon };
    setIconMapping(newMapping);
    localStorage.setItem('iconMapping', JSON.stringify(newMapping));
  };

  return (
    <IconContext.Provider value={{ iconMapping, updateIcon, isEditMode, setEditMode: setIsEditMode }}>
      {children}
    </IconContext.Provider>
  );
}

export function useIcons() {
  const context = useContext(IconContext);
  if (!context) {
    throw new Error('useIcons must be used within an IconProvider');
  }
  return context;
}