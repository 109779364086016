import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import HGM_icon from "../assets/HGM_icon.png";

interface LogoProps {
  mode: 'default' | 'pro' | 'admin';
  className?: string;
}

export function Logo({ mode, className = '' }: LogoProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Reset animation state when navigating away from home
    if (location.pathname !== '/') {
      setIsExpanded(false);
      return;
    }

    // Only animate on first visit to home page
    if (!hasAnimated && mode === 'default') {
      setIsExpanded(true);
      const timer = setTimeout(() => {
        setIsExpanded(false);
        setHasAnimated(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [location.pathname, mode, hasAnimated]);

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div className="relative overflow-hidden">
        <AnimatePresence initial={false} mode="wait">
          {isExpanded ? (
            <motion.span
              key="full"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: 'auto' }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
              className="inline-block whitespace-nowrap text-3xl font-bold"
            >
              Welcome to Home Growers Market!
            </motion.span>
          ) : (
            <motion.div
              key="short"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center justify-center"
            >
              <img
                src={HGM_icon}
                alt="HGM+"
                className="max-w-full h-auto"
                style={{ maxWidth: windowWidth > 768 ? '150px' : '100px' }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {mode !== 'default' && (
        <>
          <span className="mx-2 text-2xl">|</span>
          <span className="text-3xl">{mode.toUpperCase()}</span>
        </>
      )}
    </div>
  );
}
