import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SignUp, SignIn } from '@clerk/clerk-react'; // Removed ClerkProvider
import { AuthProvider } from './contexts/AuthContext';
import { IconProvider } from './contexts/IconContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Navbar } from './components/Navbar';
import { Home } from './pages/Home';
import { ProHome } from './pages/pro/ProHome';
import { AdminHome } from './pages/admin/AdminHome';
import { Marketplace } from './pages/Marketplace';
import { Learning } from './pages/Learning';
import { Events } from './pages/Events';
import { Messages } from './pages/Messages';
import { Contribute } from './pages/Contribute';
import { ResetPassword } from './pages/auth/ResetPassword';
import { AdminPortal } from './pages/admin/AdminPortal';
import { Discussions } from './pages/Discussions';
import { Profile } from './pages/user/Profile';
import { Settings } from './pages/user/Settings';
import { Policies } from './pages/Policies';
import { AuthGuard } from './components/AuthGuard';
import { NotFoundPage } from './pages/NotFoundPage';
import { NoAccess } from './pages/NoAccess';
import { StoreHome } from './pages/store/StoreHome';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { UserManagement } from './pages/admin/UserManagement';
import { AdminSettings } from './pages/admin/AdminSettings';
import { PostModeration } from './pages/admin/PostModeration';
import MembershipSelect from './pages/auth/MembershipSelect';
import { Login } from './pages/auth/Login';
import { Signup } from './pages/auth/Signup';

const publishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!publishableKey) {
  console.error('Missing Clerk Publishable Key. Ensure VITE_CLERK_PUBLISHABLE_KEY is set in your .env file.');
}

export default function App() {
  return (
    <AuthProvider>
      <IconProvider>
        <Router>
          <ErrorBoundary>
            <div className="min-h-screen bg-green-50">
              <Navbar />
              <main className="container mx-auto px-1 py-1 sm:py-20 mt-0">
                <Routes>
                  {/* Default route */}
                  <Route path="/" element={<Home />} />
                  <Route path="/pro" element={<ProHome />} />
                  <Route path="/store" element={<StoreHome />} />
                  <Route
                    path="/membership"
                    element={<MembershipSelect userAccount={{ membership: 'Non-member' }} />}
                  />

                  {/* Admin routes */}
                  <Route path="/admin" element={<AdminHome />} />
                  <Route path="/admin/dashboard" element={<AdminDashboard />} />
                  <Route path="/admin/users" element={<UserManagement />} />
                  <Route path="/admin/reports" element={<PostModeration />} />
                  <Route path="/admin/settings" element={<AdminSettings />} />
                  <Route path="/admin/posts" element={<PostModeration />} />
                  <Route path="/admin/portal" element={<AdminPortal />} />

                  {/* User routes */}
                  <Route
                    path="/profile"
                    element={
                      <AuthGuard>
                        <Profile />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <AuthGuard>
                        <Settings />
                      </AuthGuard>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/policies" element={<Policies />} />

                  {/* Clerk-auth routes */}
                  {publishableKey && (
                    <>
                      <Route
                        path="/sign-up"
                        element={<SignUp routing="path" path="/sign-up" signInUrl="/sign-in" />}
                      />
                      <Route
                        path="/sign-in"
                        element={<SignIn routing="path" path="/sign-in" signUpUrl="/sign-up" />}
                      />
                    </>
                  )}

                  {/* Community routes */}
                  <Route path="/marketplace" element={<Marketplace />} />
                  <Route path="/learning" element={<Learning />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/discussions/*" element={<Discussions />} />
                  <Route
                    path="/messages"
                    element={
                      <AuthGuard>
                        <Messages />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path="/messages/:conversationId"
                    element={
                      <AuthGuard>
                        <Messages />
                      </AuthGuard>
                    }
                  />
                  <Route path="/contribute" element={<Contribute />} />
                  <Route path="/no-access" element={<NoAccess />} />

                  {/* Fallback for unknown routes */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </main>
            </div>
          </ErrorBoundary>
        </Router>
      </IconProvider>
    </AuthProvider>
  );
}
