import React from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

// Provide the publishable key directly
const publishableKey = 'pk_live_Y2xlcmsuaGdtLmxpZmUk';

if (!publishableKey) {
  console.error('Missing Clerk Publishable Key.');
  throw new Error('Clerk publishable key is missing.');
}

// Log for debugging (optional, remove in production)
console.log('Loaded Clerk Publishable Key:', publishableKey);

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={publishableKey}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
);
