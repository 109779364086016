import React from 'react';
import { motion } from 'framer-motion';
import { Settings as SettingsIcon, Shield, Bell, Lock, Database, Globe, Users } from 'lucide-react';

const SETTING_SECTIONS = [
  {
    title: 'Security',
    icon: Shield,
    settings: [
      { name: 'Two-Factor Authentication', enabled: true },
      { name: 'IP Whitelist', enabled: false },
      { name: 'Session Management', enabled: true }
    ]
  },
  {
    title: 'Notifications',
    icon: Bell,
    settings: [
      { name: 'Security Alerts', enabled: true },
      { name: 'User Reports', enabled: true },
      { name: 'System Updates', enabled: true }
    ]
  },
  // Add more sections...
];

export function AdminSettings() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="p-6 max-w-7xl mx-auto"
    >
      <h1 className="text-2xl font-bold mb-8">Admin Settings</h1>
      
      <div className="grid md:grid-cols-2 gap-8">
        {SETTING_SECTIONS.map((section) => (
          <div key={section.title} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center space-x-3 mb-6">
              <section.icon className="h-6 w-6 text-purple-600" />
              <h2 className="text-xl font-semibold">{section.title}</h2>
            </div>
            <div className="space-y-4">
              {section.settings.map((setting) => (
                <div key={setting.name} className="flex items-center justify-between">
                  <span>{setting.name}</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={setting.enabled}
                      onChange={() => {}}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
}