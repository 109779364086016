import { Message, Conversation } from '../types/messages';

export const MOCK_MESSAGES: Message[] = [
  {
    id: '1',
    conversationId: 'conv1',
    sender: "Jane Smith",
    content: "Thanks for the gardening tips!",
    timestamp: "2 mins ago",
    unread: true
  },
  {
    id: '2',
    conversationId: 'conv2',
    sender: "John Doe",
    content: "When will the tomatoes be ready?",
    timestamp: "1 hour ago",
    unread: true
  },
  {
    id: '3',
    conversationId: 'conv3',
    sender: "Alice Johnson",
    content: "The herbs are growing well!",
    timestamp: "2 hours ago",
    unread: true
  }
];

export const MOCK_CONVERSATIONS: Conversation[] = [
  {
    id: 'conv1',
    participants: ['user1', 'user2'],
    lastMessage: "Thanks for the gardening tips!",
    timestamp: "2 mins ago",
    unreadCount: 2
  },
  {
    id: 'conv2',
    participants: ['user1', 'user3'],
    lastMessage: "When will the tomatoes be ready?",
    timestamp: "1 hour ago",
    unreadCount: 1
  },
  {
    id: 'conv3',
    participants: ['user1', 'user4'],
    lastMessage: "The herbs are growing well!",
    timestamp: "2 hours ago",
    unreadCount: 0
  }
];