import React from 'react';
import { Link } from 'react-router-dom';

export const NoAccess = () => (
  <div className="text-center">
    <h1 className="text-4xl font-bold">Access Denied</h1>
    <p className="text-lg mt-4">
      You do not have permission to view this page.
    </p>
    <Link to="/" className="text-blue-500 hover:underline mt-6">
      Go back to the homepage
    </Link>
  </div>
);