import React, { createContext, useContext } from 'react';
import { useUser, useClerk } from '@clerk/clerk-react';
import { db } from '../lib/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

interface AuthContextType {
  currentUser: any; // Use Clerk's User type
  loading: boolean;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();

  const syncUserToFirestore = async () => {
    if (user) {
      const userDoc = await getDoc(doc(db, 'users', user.id));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', user.id), {
          email: user.primaryEmailAddress?.emailAddress,
          displayName: user.fullName,
          createdAt: new Date(),
          role: user.publicMetadata?.role || 'user',
          status: {
            active: true,
            approved: true
          }
        });
      }
    }
  };

  React.useEffect(() => {
    if (isLoaded) {
      syncUserToFirestore();
    }
  }, [user, isLoaded]);

  const logout = async () => {
    await signOut();
  };

  const value = {
    currentUser: user,
    loading: !isLoaded,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoaded && children}
    </AuthContext.Provider>
  );
}
