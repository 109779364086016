import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Book, Search, Play, Award, BookOpen, Video, CheckCircle, Star } from 'lucide-react';

const CATEGORIES = [
  { 
    title: 'Vegetable Growing',
    articles: 45,
    courses: 12,
    icon: '🥬',
    featured: true,
    subcategories: ['Root Vegetables', 'Leafy Greens', 'Tomatoes', 'Herbs']
  },
  { 
    title: 'Soil Management',
    articles: 32,
    courses: 8,
    icon: '🌱',
    featured: false,
    subcategories: ['Composting', 'pH Balance', 'Nutrients', 'Testing']
  },
  { 
    title: 'Pest Control',
    articles: 28,
    courses: 6,
    icon: '🐛',
    featured: false,
    subcategories: ['Natural Solutions', 'Prevention', 'Identification', 'Treatment']
  }
];

const COURSES = [
  {
    id: 1,
    title: 'Organic Farming 101',
    instructor: 'Dr. Sarah Green',
    duration: '6 hours',
    modules: 8,
    certified: true,
    badge: 'Organic Expert',
    rating: 4.8,
    students: 1234,
    progress: 0,
    thumbnail: 'https://images.unsplash.com/photo-1592492152545-9695d3f473f4',
    category: 'Sustainable Practices'
  },
  {
    id: 2,
    title: 'Heavy Metals Testing',
    instructor: 'Prof. Michael Brown',
    duration: '4 hours',
    modules: 6,
    certified: true,
    badge: 'Lab Analysis Pro',
    rating: 4.6,
    students: 856,
    progress: 30,
    thumbnail: 'https://images.unsplash.com/photo-1576086213369-97a306d36557',
    category: 'Soil Management'
  }
];

export function Learning() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('grid');
  const [filter, setFilter] = useState('all');

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Hero Section */}
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1591115765373-5207764f72e4?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-white mb-4">Learning Center</h1>
            <p className="text-green-50 mb-6 max-w-2xl mx-auto">
              Discover comprehensive resources for sustainable farming and gardening
            </p>
          </div>

          {/* Search and Filters */}
          <div className="mt-10 max-w-xl mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Search articles and courses..."
                className="w-full pl-12 pr-4 py-3 rounded-xl bg-white/10 backdrop-blur-sm border border-white/20 text-white placeholder-white/70 focus:outline-none focus:ring-2 focus:ring-white/50"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-4 top-3.5 h-5 w-5 text-white/70" />
            </div>

            <div className="mt-4 flex justify-center space-x-4">
              <button
                onClick={() => setFilter('all')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  filter === 'all' 
                    ? 'bg-white text-green-800' 
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
              >
                All Content
              </button>
              <button
                onClick={() => setFilter('articles')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  filter === 'articles' 
                    ? 'bg-white text-green-800' 
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
              >
                Articles
              </button>
              <button
                onClick={() => setFilter('courses')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  filter === 'courses' 
                    ? 'bg-white text-green-800' 
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
              >
                Video Courses
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Categories Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        {CATEGORIES.map((category) => (
          <motion.div
            key={category.title}
            whileHover={{ scale: 1.02 }}
            className={`relative group bg-white rounded-xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-lg ${
              category.featured ? 'md:col-span-2' : ''
            }`}
          >
            <div className="p-6">
              <div className="flex items-start justify-between">
                <div>
                  <span className="text-3xl mb-3 block">{category.icon}</span>
                  <h3 className="text-lg font-semibold text-gray-900 mb-1">
                    {category.title}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {category.articles} articles • {category.courses} courses
                  </p>
                </div>
              </div>

              <div className="mt-4 flex flex-wrap gap-2">
                {category.subcategories.map((sub) => (
                  <span
                    key={sub}
                    className="px-2 py-1 bg-green-50 text-green-700 text-sm rounded-lg"
                  >
                    {sub}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Featured Courses */}
      <div className="mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Featured Courses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {COURSES.map((course) => (
            <motion.div
              key={course.id}
              whileHover={{ scale: 1.02 }}
              className="bg-white rounded-xl shadow-md overflow-hidden"
            >
              <div className="relative">
                <img
                  src={course.thumbnail}
                  alt={course.title}
                  className="w-full h-48 object-cover"
                />
                {course.certified && (
                  <div className="absolute top-2 right-2 px-2 py-1 bg-green-600 text-white text-sm rounded-lg flex items-center space-x-1">
                    <CheckCircle className="h-4 w-4" />
                    <span>Certified</span>
                  </div>
                )}
              </div>

              <div className="p-6">
                <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
                <p className="text-gray-600 mb-4">{course.instructor}</p>

                <div className="flex items-center justify-between text-sm text-gray-500 mb-4">
                  <div className="flex items-center space-x-4">
                    <span className="flex items-center space-x-1">
                      <Video className="h-4 w-4" />
                      <span>{course.duration}</span>
                    </span>
                    <span className="flex items-center space-x-1">
                      <Star className="h-4 w-4 text-yellow-400" />
                      <span>{course.rating}</span>
                    </span>
                  </div>
                  <span>{course.students.toLocaleString()} students</span>
                </div>

                {course.progress > 0 && (
                  <div className="w-full bg-gray-100 rounded-full h-2 mb-4">
                    <div
                      className="bg-green-600 h-2 rounded-full"
                      style={{ width: `${course.progress}%` }}
                    />
                  </div>
                )}

                <button className="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors">
                  {course.progress > 0 ? 'Continue Learning' : 'Start Learning'}
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}