import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, MessageSquare, Users } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Companion Planting Success Story',
    author: 'PermaculturePro',
    content: 'Here is how I implemented the Three Sisters method in my garden...',
    likes: 45,
    replies: 23,
    tags: ['Companion Planting', 'Traditional Methods']
  },
  {
    id: 2,
    title: 'Food Forest Design Tips',
    author: 'ForestGardener',
    content: 'Planning your food forest? Here are key considerations...',
    likes: 38,
    replies: 15,
    tags: ['Food Forest', 'Design']
  }
];

export function Permaculture() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Permaculture Discussion</h1>
          <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
            New Post
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-green-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-green-700 mb-2">
              <Users className="h-5 w-5" />
              <span className="font-semibold">Active Members</span>
            </div>
            <p className="text-2xl font-bold text-green-800">1,234</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-green-700 mb-2">
              <MessageSquare className="h-5 w-5" />
              <span className="font-semibold">Total Discussions</span>
            </div>
            <p className="text-2xl font-bold text-green-800">456</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-green-700 mb-2">
              <Leaf className="h-5 w-5" />
              <span className="font-semibold">Projects Shared</span>
            </div>
            <p className="text-2xl font-bold text-green-800">89</p>
          </div>
        </div>

        <div className="space-y-4">
          {MOCK_POSTS.map(post => (
            <div key={post.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <h2 className="text-xl font-semibold text-green-700 mb-2">{post.title}</h2>
              <p className="text-gray-600 mb-3">{post.content}</p>
              <div className="flex flex-wrap gap-2 mb-3">
                {post.tags.map(tag => (
                  <span key={tag} className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex items-center justify-between text-gray-500 text-sm">
                <span>Posted by {post.author}</span>
                <div className="flex items-center gap-4">
                  <span>{post.likes} likes</span>
                  <span>{post.replies} replies</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}