import { motion } from 'framer-motion';
import { SignIn } from '@clerk/clerk-react';
import beachy from '../../assets/homecali.jpeg';

const publishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

export function Login() {
  if (!publishableKey) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="min-h-screen flex items-center justify-center bg-cover bg-center py-12 px-4 sm:px-6 lg:px-8"
        style={{ backgroundImage: `url(${beachy})`, borderRadius: '1.5rem' }}
      >
        <div className="text-center bg-white bg-opacity-80 p-8 rounded-3xl shadow-lg">
          <h1 className="text-3xl font-extrabold text-gray-900">Authentication Disabled</h1>
          <p className="mt-4 text-gray-700">
            The application is running in limited mode, and login is currently unavailable.
          </p>
          <button
            className="mt-6 px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md shadow-md"
            onClick={() => (window.location.href = '/sign-up')}
          >
            Sign Up
          </button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="min-h-screen flex items-center justify-center bg-cover bg-center py-12 px-4 sm:px-6 lg:px-8"
      style={{ backgroundImage: `url(${beachy})`, borderRadius: '1.5rem' }}
    >
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-80 p-8 rounded-3xl shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome
          </h2>
          <p className="mt-2 text-center text-gray-600">
            Sign in to your account or create a new one.
          </p>
        </div>

        <div className="mt-8 space-y-4">
          {/* Clerk Sign In Component */}
          <SignIn
            path="/sign-in"
            routing="path"
            signUpUrl="/sign-up"
            appearance={{
              elements: {
                rootBox: 'bg-transparent shadow-none',
                card: 'bg-white rounded-xl shadow-lg',
              },
            }}
          />
          <div className="text-center">
            <p className="text-gray-700">Don’t have an account?</p>
            <button
              className="mt-4 w-full px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md shadow-md"
              onClick={() => (window.location.href = '/sign-up')}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
