import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { storage, db, auth } from '../lib/firebase';

export function useProfileImage() {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadProfileImage = async (imageBlob: Blob) => {
    if (!auth.currentUser) {
      throw new Error('No authenticated user');
    }

    setUploading(true);
    setError(null);

    try {
      // Create a unique filename
      const filename = `avatar_${Date.now()}.jpg`;
      const storageRef = ref(storage, `users/${auth.currentUser.uid}/profile/${filename}`);

      // Upload the image
      await uploadBytes(storageRef, imageBlob);
      
      // Get the download URL
      const downloadUrl = await getDownloadURL(storageRef);

      // Update auth profile
      await updateProfile(auth.currentUser, {
        photoURL: downloadUrl
      });

      // Update Firestore document
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        photoURL: downloadUrl,
        updatedAt: new Date()
      });

      return downloadUrl;
    } catch (err) {
      console.error('Error uploading profile image:', err);
      const message = err instanceof Error ? err.message : 'Failed to upload image';
      setError(message);
      throw new Error(message);
    } finally {
      setUploading(false);
    }
  };

  return {
    uploadProfileImage,
    uploading,
    error
  };
}