import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, Search, MessageSquare } from 'lucide-react';

const TREE_POSTS = [
  {
    id: 1,
    title: 'What type of maple is this?',
    image: 'https://images.unsplash.com/photo-1542202229-7d93c33f5d07?auto=format&fit=crop&q=80&w=400',
    author: 'TreeLover',
    replies: 9,
    status: 'Identified',
    species: 'Japanese Maple'
  },
  {
    id: 2,
    title: 'Strange growth on oak tree',
    image: 'https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?auto=format&fit=crop&q=80&w=400',
    author: 'Arborist123',
    replies: 14,
    status: 'Pending'
  }
];

export function Trees() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Tree Identification</h1>
          <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
            New Post
          </button>
        </div>

        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search tree identifications..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <div className="space-y-4">
          {TREE_POSTS.map(post => (
            <div key={post.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <div className="flex gap-4">
                <img
                  src={post.image}
                  alt="Tree to identify"
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-start mb-2">
                    <h2 className="text-xl font-semibold text-green-700">{post.title}</h2>
                    <span className={`px-3 py-1 rounded-full text-sm ${
                      post.status === 'Identified' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {post.status}
                    </span>
                  </div>
                  <p className="text-gray-600 mb-2">Posted by {post.author}</p>
                  {post.species && (
                    <div className="flex items-center gap-2 text-green-600 mb-2">
                      <Leaf className="h-4 w-4" />
                      <span>Identified as: {post.species}</span>
                    </div>
                  )}
                  <div className="flex items-center gap-2 text-gray-500">
                    <MessageSquare className="h-4 w-4" />
                    <span>{post.replies} replies</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}