import React from 'react';
import { Link } from 'react-router-dom';
import beachy from '../assets/homecali.jpeg';
import { motion } from 'framer-motion';

import {
  MessageSquare,
  ShoppingBag,
  BookOpen,
  Calendar,
  User,
  ChevronDown,
  MessageCircle,
  ArrowDown,
  ArrowUp,
  Settings,
  LayoutDashboard,
  ShoppingCart,
  BarChart2,
  
  Shield,
  AlertTriangle,
  Users,
  Milk,
  Flower,
  LeafyGreen,
  HeartHandshake,
  PersonStanding,
  Sprout,
  ShoppingBasket,
  Pizza,
  Palette,
  Stars,
  Users2,
  Heart,
  SearchIcon,
  FlowerIcon
} from 'lucide-react';
import { Discussions } from './Discussions';
export function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen"
    >
     {/* Hero Section */}
<div className="block md:hidden relative h-[700px] sm:h-[400px] text-center overflow-hidden rounded-lg">
  <div
    className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
    style={{
      backgroundImage: `url(${beachy})`,
    }}
  />
  <div className="absolute inset-0 bg-gradient-to-b from-green-10/10 to-green-100/10" />

  <div className="relative z-10 flex flex-col items-center justify-center h-full px-4">
    <h1 className="text-5xl font-bold mb-6 text-white drop-shadow-lg">
      Welcome to Home Growers Market!
    </h1>
    <p className="text-2xl mb-12 text-white/90 max-w-2xl drop-shadow-md">
      Your community for sustainable living and organic farming
    </p>
    <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
      <Link
        to="/marketplace"
        className="flex items-center space-x-2 bg-white text-green-700 px-8 py-4 rounded-lg font-semibold hover:bg-green-50 transform hover:scale-105 transition-all shadow-lg"
      >
        <ShoppingBag className="h-5 w-5" />
        <span>Browse Marketplace</span>
      </Link>
      <Link
        to="/discussions"
        className="flex items-center space-x-2 bg-emerald-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-green-500 border border-white/20 transform hover:scale-105 transition-all shadow-lg"
      >
        <MessageSquare className="h-5 w-5" />
        <span>Join Discussions</span>
      </Link>
      <Link
        to="/discussions"
        className="flex items-center space-x-2 bg-yellow-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-green-500 border border-white/20 transform hover:scale-105 transition-all shadow-lg"
      >
        <BookOpen className="h-5 w-5" />
        <span>Learning Center</span>
      </Link>
      <Link
        to="/events"
        className="flex items-center space-x-2 bg-indigo-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-green-500 border border-white/20 transform hover:scale-105 transition-all shadow-lg"
      >
        <Calendar className="h-5 w-5" />
        <span>Local Events</span>
      </Link>
    </div>
  </div>
</div>

      {/* Features Section */}
      <div  className="hidden sm:block relative h-[700px] sm:h-[1100px] text-center overflow-hidden rounded-lg"
>
  {/* Background Image */}
  <div
    className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
    style={{
      backgroundImage: `url(${beachy})`,
    }}
  />
  <div className="relative z-10 flex flex-col items-center justify-center h-full px-4">
          <h1 className="text-5xl font-bold mb-6 text-white drop-shadow-lg">
            Welcome to Home Growers Market!
          </h1>
          <p className="text-2xl mb-12 text-white/90 max-w-2xl drop-shadow-md">
            Your community for sustainable living and organic farming
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 sm:hidden"></div>
  {/* Tiles Overlay */}
  <div className="relative z-10 max-w-7xl mx-auto px-4 py-20">
    <div className="grid md:grid-cols-3 gap-14">
      {/* Tile 1 */}

      <Link
  to="/marketplace"
  className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"

>
  <ShoppingBag className="h-12 w-12 text-green-600 mb-1" />
  <h3 className="text-xl font-bold mb-2">Local Marketplace</h3>
  <p className="text-gray-600">
    Connect with local growers and find sustainable products in your area.
  </p>
</Link>

      {/* Tile 2 */}
      <Link
        to="/discussions/gardens"
        className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"
 >
        <FlowerIcon className="h-12 w-12 text-green-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Community Gardens</h3>
        <p className="text-gray-600">
          Find community gardens in your urban city, be inspired, and find community.
        </p>
      </Link>

      {/* Tile 3 */}
      <Link
        to="/Events"
        className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"
        >
        <Calendar className="h-12 w-12 text-green-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Events</h3>
        <p className="text-gray-600">
        Learn to live sustainably with expert-led, hyper-local classes on gardening, cooking, cleaning, and more!
          
        </p>
      </Link>

      {/* Tile 4 */}
      <Link
        to="/discussions"
        className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"
        >
        <MessageSquare className="h-12 w-12 text-green-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Community Discussions</h3>
        <p className="text-gray-600">
          Share knowledge and learn from experienced growers in your community.
        </p>
      </Link>

      {/* Tile 5 */}
      <Link
        to="/messages"
        className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"
        >
        <MessageCircle className="h-12 w-12 text-green-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Connect</h3>
        <p className="text-gray-600">
          Connect directly with other members to share tips, photos, and years of experience.
        </p>
      </Link>

      {/* Tile 6 */}
      <Link
        to="/events"
        className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center"
        >
        <BookOpen className="h-12 w-12 text-green-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Learning Center</h3>
        <p className="text-gray-600">
          Access educational resources and tutorials for sustainable growing.
        </p>
      </Link>

      {/* Tile 7 */}
      <Link
        to="/membership"
        className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-110 transition-all flex flex-col items-center text-center md:col-span-1 md:col-start-2"

        >
        <Heart className="h-12 w-12 text-rose-600 mb-1" />
        <h3 className="text-xl font-bold mb-2">Join the Community!</h3>
        <p className="text-gray-600">
          Become a member and help empower each other to grow, learn, and build a brighter (greener) future. Together.
        </p>
      </Link>
    </div>
  </div>
  </div>
  
      </div>
    </motion.div>
  );
}
