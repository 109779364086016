import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, MapPin, DollarSign } from 'lucide-react';

const JOBS = [
  {
    id: 1,
    title: 'Farm Manager',
    company: 'Green Valley Organics',
    location: 'Portland, OR',
    salary: '$45,000 - $60,000',
    type: 'Full-time',
    posted: '2 days ago'
  },
  {
    id: 2,
    title: 'Greenhouse Specialist',
    company: 'Urban Growers Co-op',
    location: 'Seattle, WA',
    salary: '$35,000 - $45,000',
    type: 'Full-time',
    posted: '1 week ago'
  }
];

export function JobBoard() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Agricultural Job Board</h1>
        <div className="flex gap-4 mb-6">
          <input
            type="text"
            placeholder="Search jobs..."
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          />
          <button className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700">
            Search
          </button>
        </div>
        
        <div className="space-y-4">
          {JOBS.map(job => (
            <div key={job.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start mb-2">
                <h2 className="text-xl font-semibold text-green-700">{job.title}</h2>
                <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                  {job.type}
                </span>
              </div>
              <div className="text-gray-600 space-y-2">
                <div className="flex items-center gap-2">
                  <Briefcase className="h-4 w-4" />
                  <span>{job.company}</span>
                </div>
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4" />
                  <span>{job.location}</span>
                </div>
                <div className="flex items-center gap-2">
                  <DollarSign className="h-4 w-4" />
                  <span>{job.salary}</span>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <span className="text-sm text-gray-500">Posted {job.posted}</span>
                <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
                  Apply Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}