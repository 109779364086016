import { create } from 'zustand';

interface UserStatus {
  active: boolean;
  approved: boolean;
  notes: string[];
  lastModified: Date;
  modifiedBy: string;
}

interface UserData {
  id: string;
  email: string;
  displayName: string;
  role: 'admin' | 'user' | 'moderator';
  status: UserStatus;
  createdAt: Date;
  permissions: string[];
  subscription: 'free' | 'pro' | 'admin';
  history: {
    action: string;
    timestamp: Date;
    by: string;
    note?: string;
  }[];
}

interface UserStore {
  users: Record<string, UserData>;
  updateUser: (userId: string, updates: Partial<UserData>) => void;
  addUserNote: (userId: string, note: string, by: string) => void;
}

// Admin permissions include everything a pro user has plus admin-specific permissions
const ADMIN_PERMISSIONS = [
  // Pro features
  'access_pro_features',
  'create_unlimited_listings',
  'advanced_analytics',
  'priority_support',
  'custom_branding',
  'bulk_operations',
  'api_access',
  // Admin specific
  'manage_users',
  'manage_content',
  'manage_settings',
  'view_analytics',
  'manage_permissions',
  'system_configuration',
  'audit_logs',
  'emergency_actions',
  'feature_management',
  'content_moderation'
];

const initialUsers: Record<string, UserData> = {
  'admin': {
    id: 'admin-id',
    email: 'admin@example.com',
    displayName: 'Admin',
    role: 'admin',
    status: {
      active: true,
      approved: true,
      notes: ['Initial admin account'],
      lastModified: new Date(),
      modifiedBy: 'system'
    },
    createdAt: new Date(),
    permissions: ADMIN_PERMISSIONS,
    subscription: 'admin',
    history: [{
      action: 'account_created',
      timestamp: new Date(),
      by: 'system'
    }]
  },
  // ... rest of the users remain the same
};

export const useUserStore = create<UserStore>((set) => ({
  users: initialUsers,
  updateUser: (userId: string, updates: Partial<UserData>) => 
    set((state) => ({
      users: {
        ...state.users,
        [userId]: {
          ...state.users[userId],
          ...updates,
          status: {
            ...state.users[userId].status,
            lastModified: new Date(),
            ...(updates.status || {})
          }
        }
      }
    })),
  addUserNote: (userId: string, note: string, by: string) =>
    set((state) => ({
      users: {
        ...state.users,
        [userId]: {
          ...state.users[userId],
          status: {
            ...state.users[userId].status,
            notes: [...state.users[userId].status.notes, note],
            lastModified: new Date(),
            modifiedBy: by
          }
        }
      }
    }))
}));