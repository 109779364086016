import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyD4NymPrj43fO3zngu6wcgc-aWR3epoHow",
  authDomain: "bunny-8add2.firebaseapp.com",
  projectId: "bunny-8add2",
  storageBucket: "bunny-8add2.firebasestorage.app",
  messagingSenderId: "104418625412",
  appId: "1:104418625412:web:99eee4f5432ce43f467fd1",
  measurementId: "G-DEC468M928"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();

// Set persistence for authentication
(async () => {
  try {
    await setPersistence(auth, browserLocalPersistence);
    console.log('Authentication persistence set to browserLocalPersistence');
  } catch (error) {
    console.error('Error setting authentication persistence:', error);
  }
})();

// Initialize Analytics if supported
export let analytics: ReturnType<typeof getAnalytics> | null = null;
isSupported()
  .then((supported) => {
    if (supported) {
      analytics = getAnalytics(app);
      console.log('Firebase Analytics initialized');
    }
  })
  .catch((error) => console.error('Error initializing Firebase Analytics:', error));

/**
 * Initializes the admin account. Creates an admin account if it doesn't already exist.
 */
export const initializeAdminAccount = async () => {
  const adminEmail = 'admin@example.com';
  const adminPassword = 'Password';
  
  try {
    // Check if the admin account exists by attempting to sign in
    try {
      await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
      console.log('Admin account already exists and signed in successfully.');
    } catch (signInError) {
      // If sign in fails, create the admin account
      console.warn('Admin account not found, attempting to create one:', signInError);
      const { user } = await createUserWithEmailAndPassword(auth, adminEmail, adminPassword);
      
      // Add admin user details to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: adminEmail,
        displayName: 'Admin',
        role: 'admin',
        createdAt: new Date(),
        status: {
          active: true,
          approved: true
        }
      });
      console.log('Admin account created successfully.');
    }

    // Sign out after initialization
    await auth.signOut();
    console.log('Admin initialization complete and signed out.');
  } catch (error) {
    console.error('Error initializing admin account:', error);
  }
};

export { app };
