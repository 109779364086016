import React from 'react';
import { motion } from 'framer-motion';
import { Shield, AlertTriangle, Image, MessageSquare, Users } from 'lucide-react';

export function Policies() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-4xl mx-auto"
    >
      <div className="bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold mb-8">Community Guidelines & Policies</h1>

        <div className="prose max-w-none">
          <div className="mb-8">
            <div className="flex items-center space-x-2 mb-4">
              <Shield className="h-6 w-6 text-green-600" />
              <h2 className="text-xl font-semibold m-0">Content Guidelines</h2>
            </div>
            <p className="text-gray-600">
              HGM is committed to maintaining a safe and welcoming environment for all users. 
              We reserve the right to remove any content that violates our policies, including:
            </p>
            <ul className="space-y-2 mt-4">
              <li className="flex items-start space-x-2">
                <AlertTriangle className="h-5 w-5 text-red-500 mt-1 flex-shrink-0" />
                <span>Harmful, hateful, or discriminatory content</span>
              </li>
              <li className="flex items-start space-x-2">
                <Image className="h-5 w-5 text-red-500 mt-1 flex-shrink-0" />
                <span>Inappropriate or explicit images</span>
              </li>
              <li className="flex items-start space-x-2">
                <MessageSquare className="h-5 w-5 text-red-500 mt-1 flex-shrink-0" />
                <span>Harassment or bullying</span>
              </li>
              <li className="flex items-start space-x-2">
                <Users className="h-5 w-5 text-red-500 mt-1 flex-shrink-0" />
                <span>Impersonation or misrepresentation</span>
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Image Guidelines</h2>
            <p className="text-gray-600">
              All images must be appropriate and relevant to gardening, farming, or sustainable living. 
              We do not allow:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-600">
              <li>Explicit or inappropriate content</li>
              <li>Copyrighted material without permission</li>
              <li>Misleading or deceptive images</li>
              <li>Images promoting harmful practices</li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Enforcement</h2>
            <p className="text-gray-600">
              HGM moderators actively monitor content and may take the following actions:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-600">
              <li>Content removal</li>
              <li>Warning notifications</li>
              <li>Temporary account suspension</li>
              <li>Permanent account termination</li>
            </ul>
          </div>

          <div className="bg-yellow-50 p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-yellow-800">Important Notice</h2>
            <p className="text-yellow-700">
              HGM reserves the right to remove any content that violates these guidelines 
              without prior notice. Repeated violations may result in account suspension or 
              termination. By using our platform, you agree to abide by these guidelines 
              and any future updates.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}