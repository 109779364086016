import React from 'react';
import { motion } from 'framer-motion';
import { Recycle, Thermometer, MessageSquare, ThumbsUp, AlertTriangle } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Building a Three-Bin System',
    author: 'CompostPro',
    image: 'https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80&w=600',
    content: 'Step-by-step guide to building an efficient composting system...',
    likes: 84,
    comments: 37,
    tags: ['DIY', 'Tutorial', 'System']
  },
  {
    id: 2,
    title: 'Troubleshooting Common Issues',
    author: 'SoilMaster',
    image: 'https://images.unsplash.com/photo-1591115765373-5207764f72e4?auto=format&fit=crop&q=80&w=600',
    content: 'Solutions for common composting problems...',
    likes: 61,
    comments: 28,
    tags: ['Tips', 'Problems', 'Solutions']
  }
];

const COMPOSTING_GUIDE = [
  {
    category: 'Green Materials',
    items: ['Grass clippings', 'Food scraps', 'Coffee grounds', 'Fresh leaves']
  },
  {
    category: 'Brown Materials',
    items: ['Dry leaves', 'Straw', 'Paper', 'Wood chips']
  },
  {
    category: 'Never Compost',
    items: ['Meat/dairy', 'Oils', 'Diseased plants', 'Treated wood']
  }
];

export function Compost() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Rest of the component content remains the same */}
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Composting Community</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Learn about composting techniques and share your experiences
            </p>
            <button className="bg-white/10 backdrop-blur-sm text-white px-6 py-3 rounded-lg hover:bg-white/20 transition-colors border-2 border-green-100/20">
              Share Your Tips
            </button>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <div className="col-span-2 space-y-6">
          {MOCK_POSTS.map((post) => (
            <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2 text-green-700">{post.title}</h2>
                <p className="text-gray-600 mb-4">{post.content}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {post.tags.map((tag) => (
                    <span key={tag} className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex items-center justify-between text-gray-600">
                  <span>Posted by {post.author}</span>
                  <div className="flex items-center space-x-4">
                    <button className="flex items-center space-x-1">
                      <ThumbsUp className="h-4 w-4" />
                      <span>{post.likes}</span>
                    </button>
                    <button className="flex items-center space-x-1">
                      <MessageSquare className="h-4 w-4" />
                      <span>{post.comments}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Composting Guide</h2>
            <div className="space-y-4">
              {COMPOSTING_GUIDE.map((section) => (
                <div key={section.category} className="p-3 hover:bg-green-50 rounded-lg">
                  <h3 className="font-medium flex items-center space-x-2">
                    <Recycle className="h-4 w-4 text-green-600" />
                    <span>{section.category}</span>
                  </h3>
                  <ul className="mt-2 ml-6 space-y-1">
                    {section.items.map((item, index) => (
                      <li key={index} className="text-gray-600 text-sm">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Monitoring Tips</h2>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <Thermometer className="h-5 w-5 text-green-600 mt-1" />
                <div>
                  <h3 className="font-medium">Temperature</h3>
                  <p className="text-sm text-gray-600">Maintain 135-150°F (57-66°C) for hot composting</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <AlertTriangle className="h-5 w-5 text-green-600 mt-1" />
                <div>
                  <h3 className="font-medium">Moisture</h3>
                  <p className="text-sm text-gray-600">Keep pile as moist as a wrung-out sponge</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}