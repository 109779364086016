import React from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => (
  <div className="text-center">
    <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
    <p className="text-lg mt-4">
      Sorry, the page you're looking for doesn't exist.
    </p>
    <Link to="/" className="text-blue-500 hover:underline mt-6">
      Go back to the homepage
    </Link>
  </div>
);
