import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, Users, MessageSquare } from 'lucide-react';

const GARDENS = [
  {
    id: 1,
    name: 'Community Garden East',
    location: 'East Portland',
    members: 45,
    discussions: 156,
    description: 'A vibrant community garden focused on organic vegetables and herbs.'
  },
  {
    id: 2,
    name: 'Urban Oasis Garden',
    location: 'Downtown',
    members: 32,
    discussions: 98,
    description: 'Rooftop garden specializing in container gardening and urban farming techniques.'
  }
];

export function Gardens() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Community Gardens</h1>
        <div className="grid md:grid-cols-2 gap-4">
          {GARDENS.map(garden => (
            <div key={garden.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <h2 className="text-xl font-semibold text-green-700 mb-2">{garden.name}</h2>
              <p className="text-gray-600 mb-4">{garden.description}</p>
              <div className="flex gap-4 text-gray-600">
                <div className="flex items-center gap-1">
                  <Users className="h-4 w-4" />
                  <span>{garden.members} members</span>
                </div>
                <div className="flex items-center gap-1">
                  <MessageSquare className="h-4 w-4" />
                  <span>{garden.discussions} discussions</span>
                </div>
                <div className="flex items-center gap-1">
                  <Leaf className="h-4 w-4" />
                  <span>{garden.location}</span>
                </div>
              </div>
              <button className="mt-4 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 w-full">
                Join Garden
              </button>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}