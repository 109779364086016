import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './useAuth';
import { MOCK_MESSAGES } from '../lib/mockData';

export interface Message {
  id: string;
  conversationId: string;
  sender: string;
  content: string;
  timestamp: string;
  unread: boolean;
}

export function useMessages(conversationId?: string) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Use mock data if Firebase is not initialized or user is not authenticated
    if (!db || !currentUser) {
      setMessages(MOCK_MESSAGES);
      setLoading(false);
      return;
    }

    try {
      let q = query(
        collection(db, 'messages'),
        where('participants', 'array-contains', currentUser.uid),
        orderBy('timestamp', 'desc'),
        limit(10)
      );

      if (conversationId) {
        q = query(
          collection(db, 'messages'),
          where('conversationId', '==', conversationId),
          orderBy('timestamp', 'desc')
        );
      }

      const unsubscribe = onSnapshot(q, 
        (snapshot) => {
          const newMessages = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              conversationId: data.conversationId,
              sender: data.sender,
              content: data.content,
              timestamp: formatTimestamp(data.timestamp),
              unread: data.readBy ? !data.readBy.includes(currentUser.uid) : true
            };
          });
          setMessages(newMessages);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
          // Fallback to mock data on error
          setMessages(MOCK_MESSAGES);
        }
      );

      return () => unsubscribe();
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      // Fallback to mock data on error
      setMessages(MOCK_MESSAGES);
    }
  }, [currentUser, conversationId]);

  return { messages, loading, error };
}

function formatTimestamp(timestamp: Timestamp): string {
  const now = new Date();
  const date = timestamp.toDate();
  const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));

  if (diffInMinutes < 1) return 'Just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
  if (diffInMinutes < 10080) return `${Math.floor(diffInMinutes / 1440)}d ago`;
  
  return date.toLocaleDateString();
}