import React from 'react';
import { motion } from 'framer-motion';
import { useUserStore } from '../../lib/sampleData';
import { useAuth } from '../../hooks/useAuth';
import { Shield, AlertTriangle, CheckCircle, XCircle, History, FileText } from 'lucide-react';

export function AdminPortal() {
  const { users, updateUser, addUserNote } = useUserStore();
  const [selectedUser, setSelectedUser] = React.useState<string | null>(null);
  const [note, setNote] = React.useState('');
  const { user } = useAuth();

  const handleStatusChange = (userId: string, status: { active: boolean; approved: boolean }) => {
    updateUser(userId, {
      status: {
        ...users[userId].status,
        ...status,
        modifiedBy: user?.email || 'admin'
      }
    });
  };

  const handleAddNote = (userId: string) => {
    if (note.trim()) {
      addUserNote(userId, note, user?.email || 'admin');
      setNote('');
    }
  };

  const handleTestCrash = () => {
    throw new Error('Test crash initiated by admin');
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100"
    >
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Admin Portal</h1>
            <button
              onClick={handleTestCrash}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Test Crash
            </button>
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            {/* User List */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">User Management</h2>
              {Object.entries(users).map(([userId, userData]) => (
                <div 
                  key={userId}
                  className="border rounded-lg p-4 hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium">{userData.displayName}</h3>
                      <p className="text-sm text-gray-600">{userData.email}</p>
                      <div className="flex items-center space-x-2 mt-2">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          userData.status.active 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {userData.status.active ? 'Active' : 'Inactive'}
                        </span>
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          userData.status.approved 
                            ? 'bg-blue-100 text-blue-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {userData.status.approved ? 'Approved' : 'Pending'}
                        </span>
                      </div>
                    </div>
                    <div className="space-x-2">
                      <button
                        onClick={() => handleStatusChange(userId, { 
                          ...userData.status,
                          active: !userData.status.active 
                        })}
                        className={`p-2 rounded-lg ${
                          userData.status.active 
                            ? 'bg-red-100 text-red-600 hover:bg-red-200' 
                            : 'bg-green-100 text-green-600 hover:bg-green-200'
                        }`}
                      >
                        {userData.status.active ? <XCircle size={16} /> : <CheckCircle size={16} />}
                      </button>
                      <button
                        onClick={() => setSelectedUser(userId)}
                        className="p-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200"
                      >
                        <History size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* User Details */}
            {selectedUser && (
              <div className="space-y-4">
                <h2 className="text-lg font-semibold">User Details</h2>
                <div className="border rounded-lg p-4">
                  <h3 className="font-medium mb-4">History</h3>
                  <div className="space-y-2">
                    {users[selectedUser].history.map((event, index) => (
                      <div key={index} className="text-sm">
                        <div className="flex justify-between text-gray-600">
                          <span>{event.action}</span>
                          <span>{new Date(event.timestamp).toLocaleDateString()}</span>
                        </div>
                        {event.note && (
                          <p className="text-gray-500 mt-1">{event.note}</p>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="mt-4">
                    <h3 className="font-medium mb-2">Add Note</h3>
                    <textarea
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                      rows={3}
                      placeholder="Enter note..."
                    />
                    <button
                      onClick={() => handleAddNote(selectedUser)}
                      className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Add Note
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}