import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useUser, useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { User as DefaultUserIcon } from "lucide-react"; // Default person icon

export const ProfileMenu: React.FC = () => {
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser(); // Clerk's user hook for accessing the user object
  const { signOut } = useAuth();

  const handleNavigate = (path: string) => {
    setShowAccountMenu(false);
    navigate(path);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <div
      className="relative"
      onMouseLeave={() => setShowAccountMenu(false)} // Close menu on mouse leave
    >
      {/* User Button or Default Icon */}
      <button
        className="p-2 hover:bg-white/10 rounded-full flex items-center"
        onMouseEnter={() => setShowAccountMenu(true)} // Open menu on hover
      >
        {user?.imageUrl ? (
          <img
            src={user.imageUrl}
            alt="User Avatar"
            className="rounded-full"
            style={{
              width: "clamp(32px, 5vw, 40px)",
              height: "clamp(32px, 5vw, 40px)",
            }}
          />
        ) : (
          <DefaultUserIcon
            className="text-white"
            style={{
              width: "clamp(32px, 5vw, 40px)",
              height: "clamp(32px, 5vw, 40px)",
            }}
          />
        )}
      </button>

      {/* Account Menu */}
      <AnimatePresence>
        {showAccountMenu && (
          <motion.div
            className="absolute right-0 w-48 mt-2 py-2 bg-white rounded-lg shadow-xl"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {user ? (
              <>
                <button
                  onClick={() => handleNavigate("/profile")}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Profile
                </button>
                <button
                  onClick={() => handleNavigate("/membership")}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Membership
                </button>
                <button
                  onClick={() => handleNavigate("/Policies")}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Our Policies
                </button>
                <button
                  onClick={handleSignOut}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => handleNavigate("/login")}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Login
                </button>
                <button
                  onClick={() => handleNavigate("/membership")}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Membership
                </button>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
