import React from 'react';
import { motion } from 'framer-motion';
import { BarChart2, ShoppingCart, Users, TrendingUp, Package, AlertCircle } from 'lucide-react';

export function ProHome() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen"
    >
      {/* Hero Section */}
      <div className="relative h-[500px] text-center overflow-hidden rounded-lg mb-8">
        <div 
          className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1560493676-04071c5f467b?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/70 to-blue-800/80" />
        
        <div className="relative z-10 flex flex-col items-center justify-center h-full px-4">
          <h1 className="text-5xl font-bold mb-6 text-white drop-shadow-lg">
            Welcome to HGM Pro
          </h1>
          <p className="text-2xl mb-12 text-white/90 max-w-2xl drop-shadow-md">
            Manage your business, track orders, and grow your customer base
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button className="flex items-center space-x-2 bg-white text-blue-700 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transform hover:scale-105 transition-all shadow-lg">
              <ShoppingCart className="h-5 w-5" />
              <span>View Orders</span>
            </button>
            <button className="flex items-center space-x-2 bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-500 border border-white/20 transform hover:scale-105 transition-all shadow-lg">
              <BarChart2 className="h-5 w-5" />
              <span>Analytics Dashboard</span>
            </button>
          </div>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid md:grid-cols-3 gap-8">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <Package className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">Order Management</h3>
            <p className="text-gray-600">Track and manage your orders efficiently with real-time updates.</p>
          </motion.div>

          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <Users className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">Customer Insights</h3>
            <p className="text-gray-600">Understand your customers better with detailed analytics.</p>
          </motion.div>

          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <TrendingUp className="h-12 w-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">Growth Analytics</h3>
            <p className="text-gray-600">Monitor your business growth with comprehensive metrics.</p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}