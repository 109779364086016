import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, Filter, Grid, List, MapPin, DollarSign } from 'lucide-react';

type Location = {
  latitude: number;
  longitude: number;
  address: string;
};

type ListingType = {
  id: string;
  title: string;
  price: number;
  image: string;
  seller: string;
  location: Location;
  category: string;
  distance?: number;
};

const MOCK_LISTINGS: ListingType[] = [
  {
    id: '1',
    title: 'Organic Heirloom Tomato Seeds',
    price: 4.99,
    image: 'https://images.unsplash.com/photo-1592841200221-a6898f307baa?auto=format&fit=crop&q=80&w=400',
    seller: 'Green Thumb Gardens',
    location: {
      latitude: 45.5155,
      longitude: -122.6789,
      address: 'Portland, OR'
    },
    category: 'Seeds'
  },
  {
    id: '2',
    title: 'Composting Starter Kit',
    price: 49.99,
    image: 'https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80&w=400',
    seller: 'Eco Solutions',
    location: {
      latitude: 47.6062,
      longitude: -122.3321,
      address: 'Seattle, WA'
    },
    category: 'Equipment'
  },
  {
    id: '3',
    title: 'Handcrafted Cedar Planter Box',
    price: 89.99,
    image: 'https://images.unsplash.com/photo-1622383563227-04401ab4e5ea?auto=format&fit=crop&q=80&w=400',
    seller: 'Woodcraft Workshop',
    location: {
      latitude: 30.2672,
      longitude: -97.7431,
      address: 'Austin, TX'
    },
    category: 'Tools'

  },
  {
    id: '1',
    title: 'Organic Heirloom Tomato Seeds',
    price: 4.99,
    image: 'https://images.unsplash.com/photo-1592841200221-a6898f307baa?auto=format&fit=crop&q=80&w=400',
    seller: 'Green Thumb Gardens',
    location: {
      latitude: 45.5155,
      longitude: -122.6789,
      address: 'Portland, OR'
    },
    category: 'Seeds'
  },
  {
    id: '2',
    title: 'Composting Starter Kit',
    price: 49.99,
    image: 'https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80&w=400',
    seller: 'Eco Solutions',
    location: {
      latitude: 47.6062,
      longitude: -122.3321,
      address: 'Seattle, WA'
    },
    category: 'Equipment'
  },
  {
    id: '3',
    title: 'Handcrafted Cedar Planter Box',
    price: 89.99,
    image: 'https://images.unsplash.com/photo-1622383563227-04401ab4e5ea?auto=format&fit=crop&q=80&w=400',
    seller: 'Woodcraft Workshop',
    location: {
      latitude: 30.2672,
      longitude: -97.7431,
      address: 'Austin, TX'
    },
    category: 'Tools'
    
  },
  {
    id: '1',
    title: 'Organic Heirloom Tomato Seeds',
    price: 4.99,
    image: 'https://images.unsplash.com/photo-1592841200221-a6898f307baa?auto=format&fit=crop&q=80&w=400',
    seller: 'Green Thumb Gardens',
    location: {
      latitude: 45.5155,
      longitude: -122.6789,
      address: 'Portland, OR'
    },
    category: 'Seeds'
  },
  {
    id: '2',
    title: 'Composting Starter Kit',
    price: 49.99,
    image: 'https://images.unsplash.com/photo-1588964895597-cfccd6e2dbf9?auto=format&fit=crop&q=80&w=400',
    seller: 'Eco Solutions',
    location: {
      latitude: 47.6062,
      longitude: -122.3321,
      address: 'Seattle, WA'
    },
    category: 'Equipment'
  },
  {
    id: '3',
    title: 'Handcrafted Cedar Planter Box',
    price: 89.99,
    image: 'https://images.unsplash.com/photo-1622383563227-04401ab4e5ea?auto=format&fit=crop&q=80&w=400',
    seller: 'Woodcraft Workshop',
    location: {
      latitude: 30.2672,
      longitude: -97.7431,
      address: 'Austin, TX'
    },
    category: 'Tools'
    
  }
];

export function Marketplace() {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [listings, setListings] = useState<ListingType[]>([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: 'Current Location'
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation) {
      const listingsWithDistance = MOCK_LISTINGS.map(listing => ({
        ...listing,
        distance: calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          listing.location.latitude,
          listing.location.longitude
        )
      })).sort((a, b) => (a.distance || 0) - (b.distance || 0));
      
      setListings(listingsWithDistance);
    } else {
      setListings(MOCK_LISTINGS);
    }
  }, [userLocation]);

  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 3959; // Earth's radius in miles
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(R * c);
  }

  function toRad(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1488459716781-31db52582fe9?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Local Marketplace</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Connect with local growers and find sustainable products in your area
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search listings..."
                  className="w-full pl-10 pr-4 py-3 border-2 border-green-100/20 bg-white/10 backdrop-blur-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-white placeholder-green-100/70"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-3.5 h-5 w-5 text-green-100" />
              </div>
              <div className="flex gap-2">
                <button className="p-3 bg-white/10 backdrop-blur-sm rounded-lg border-2 border-green-100/20 text-white hover:bg-white/20 transition-colors">
                  <Filter className="h-5 w-5" />
                </button>
                <div className="flex border-2 border-green-100/20 rounded-lg overflow-hidden">
                  <button
                    className={`p-3 ${viewMode === 'grid' ? 'bg-white/20' : 'bg-white/10'} backdrop-blur-sm text-white hover:bg-white/20 transition-colors`}
                    onClick={() => setViewMode('grid')}
                  >
                    <Grid className="h-5 w-5" />
                  </button>
                  <button
                    className={`p-3 ${viewMode === 'list' ? 'bg-white/20' : 'bg-white/10'} backdrop-blur-sm text-white hover:bg-white/20 transition-colors`}
                    onClick={() => setViewMode('list')}
                  >
                    <List className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {listings.map((listing) => (
          <motion.div
            key={listing.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.02 }}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <img
              src={listing.image}
              alt={listing.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{listing.title}</h3>
              <p className="text-green-600 font-bold mb-2">${listing.price.toFixed(2)}</p>
              <div className="text-sm text-gray-600">
                <p>{listing.seller}</p>
                <div className="flex items-center space-x-1 mt-1">
                  <MapPin className="h-4 w-4" />
                  <span>{listing.location.address}</span>
                </div>
                {listing.distance !== undefined && (
                  <p className="mt-1 text-green-600">
                    {listing.distance} miles away
                  </p>
                )}
              </div>
              <div className="mt-4">
                <button className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700">
                  Contact Seller
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}