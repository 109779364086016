// Update the Profile component to include the ImageUploader
import React from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import { useProfileImage } from '../../hooks/useProfileImage';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Settings, LogOut, Bell, Shield, FileText } from 'lucide-react';
import { ImageUploader } from '../../components/ImageUploader';

export function Profile() {
  const { currentUser, logout } = useAuth();
  const { uploadProfileImage, uploading } = useProfileImage();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const handleImageUpload = async (imageBlob: Blob) => {
    try {
      await uploadProfileImage(imageBlob);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-6xl mx-auto space-y-6"
    >
      {/* Profile Header */}
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center space-x-6">
          <ImageUploader
            currentImage={currentUser.photoURL}
            onImageSelect={handleImageUpload}
            loading={uploading}
          />
          <div className="flex-1">
            <h1 className="text-2xl font-bold">{currentUser.displayName || 'User'}</h1>
            <div className="flex items-center text-gray-600 mt-1">
              <Mail className="h-4 w-4 mr-2" />
              <span>{currentUser.email}</span>
            </div>
            <div className="mt-4 flex space-x-4">
              <Link 
                to="/settings"
                className="flex items-center space-x-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                <Settings className="h-4 w-4" />
                <span>Edit Profile</span>
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center space-x-2 px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200"
              >
                <LogOut className="h-4 w-4" />
                <span>Log Out</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Rest of the profile content remains the same */}
      {/* ... */}
    </motion.div>
  );
}