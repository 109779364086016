import React from 'react';
import { Search, User } from 'lucide-react';
import { useConversations } from '../../hooks/useConversations';
import { Link } from 'react-router-dom';

interface ConversationListProps {
  selectedId: string | null;
}

export function ConversationList({ selectedId }: ConversationListProps) {
  const { conversations, loading } = useConversations();
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredConversations = conversations.filter((conversation) =>
    conversation.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="col-span-4 border-r">
      <div className="p-4 border-b">
        <div className="relative">
          <input
            type="text"
            placeholder="Search messages..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-16rem)]">
        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
          </div>
        ) : (
          filteredConversations.map((conversation) => (
            <Link
              key={conversation.id}
              to={`/messages/${conversation.id}`}
              className={`p-4 border-b hover:bg-gray-50 cursor-pointer ${
                selectedId === conversation.id ? 'bg-gray-50' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="bg-green-100 p-2 rounded-full">
                    <User className="h-6 w-6 text-green-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">
                      {conversation.participants.join(', ')}
                    </h3>
                    <p className="text-sm text-gray-600 truncate">
                      {conversation.lastMessage}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-xs text-gray-500">
                    {conversation.timestamp}
                  </p>
                  {conversation.unreadCount > 0 && (
                    <span className="bg-green-500 text-white text-xs px-2 py-1 rounded-full">
                      {conversation.unreadCount}
                    </span>
                  )}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}
