import React from 'react';
import { motion } from 'framer-motion';
import { Droplets, MessageSquare, Settings } from 'lucide-react';

const POSTS = [
  {
    id: 1,
    title: 'NFT System Build Guide',
    author: 'HydroMaster',
    content: 'Complete guide to building a Nutrient Film Technique system...',
    likes: 67,
    replies: 34,
    tags: ['DIY', 'NFT']
  },
  {
    id: 2,
    title: 'pH Management Tips',
    author: 'GrowPro',
    content: 'Maintaining optimal pH levels in your hydroponic system...',
    likes: 45,
    replies: 28,
    tags: ['Maintenance', 'pH Control']
  }
];

export function Hydroponic() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Hydroponic Growing</h1>
          <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
            New Post
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-blue-700 mb-2">
              <Droplets className="h-5 w-5" />
              <span className="font-semibold">Active Systems</span>
            </div>
            <p className="text-2xl font-bold text-blue-800">324</p>
          </div>
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-blue-700 mb-2">
              <MessageSquare className="h-5 w-5" />
              <span className="font-semibold">Discussions</span>
            </div>
            <p className="text-2xl font-bold text-blue-800">567</p>
          </div>
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-blue-700 mb-2">
              <Settings className="h-5 w-5" />
              <span className="font-semibold">Build Guides</span>
            </div>
            <p className="text-2xl font-bold text-blue-800">45</p>
          </div>
        </div>

        <div className="space-y-4">
          {POSTS.map(post => (
            <div key={post.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <h2 className="text-xl font-semibold text-blue-700 mb-2">{post.title}</h2>
              <p className="text-gray-600 mb-3">{post.content}</p>
              <div className="flex flex-wrap gap-2 mb-3">
                {post.tags.map(tag => (
                  <span key={tag} className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex items-center justify-between text-gray-500 text-sm">
                <span>Posted by {post.author}</span>
                <div className="flex items-center gap-4">
                  <span>{post.likes} likes</span>
                  <span>{post.replies} replies</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}