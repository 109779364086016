import React, { useEffect, useState } from 'react';
import { useUser } from "@clerk/clerk-react";
import { db } from "../lib/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

export function useAuth() {
  const { user, isLoaded } = useUser();

  const syncUserToFirestore = async () => {
    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.id));

      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", user.id), {
          email: user.primaryEmailAddress?.emailAddress,
          displayName: user.fullName,
          createdAt: new Date(),
          role: user.publicMetadata?.role || "user",
          status: { active: true, approved: true },
        });
      }
    }
  };

  useEffect(() => {
    if (isLoaded) {
      syncUserToFirestore();
    }
  }, [user, isLoaded]);

  return { currentUser: user, loading: !isLoaded };
}
