import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Shield, AlertTriangle, Settings, Users, BarChart, FileText } from 'lucide-react';

export function AdminDashboard() {
  const [showPlan, setShowPlan] = useState(false);

  return (
    <div className="flex">
      <aside className="w-64 bg-white shadow-md h-[calc(100vh-4rem)] sticky top-16 overflow-y-auto">
        <nav className="p-4 space-y-2">
          <Link
            to="/admin"
            onClick={() => setShowPlan(false)}
            className="flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <BarChart className="h-5 w-5" />
            <span>Overview</span>
          </Link>
          <Link
            to="/admin/posts"
            onClick={() => setShowPlan(false)}
            className="flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <Shield className="h-5 w-5" />
            <span>Post Moderation</span>
          </Link>
          <Link
            to="/admin/users"
            onClick={() => setShowPlan(false)}
            className="flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <Users className="h-5 w-5" />
            <span>User Management</span>
          </Link>
          <Link
            to="/admin/reports"
            onClick={() => setShowPlan(false)}
            className="flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <AlertTriangle className="h-5 w-5" />
            <span>Reports</span>
          </Link>
          <Link
            to="/admin/settings"
            onClick={() => setShowPlan(false)}
            className="flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <Settings className="h-5 w-5" />
            <span>Settings</span>
          </Link>

          {/* Platform Plan Button */}
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowPlan(!showPlan);
            }}
            className="w-full text-left flex items-center space-x-2 p-2 hover:bg-green-50 rounded-lg text-gray-700"
          >
            <FileText className="h-5 w-5" />
            <span>Platform Plan</span>
          </button>

          {/* Show timeline sections if plan is visible */}
          {showPlan && (
            <div className="ml-6 mt-2 space-y-1">
              <h3 className="font-bold text-sm">Timeline Sections</h3>
              {monthSections.map((section) => (
                <a
                  key={section.id}
                  href={`#${section.id}`}
                  className="block text-emerald-600 hover:underline text-sm"
                  onClick={() => {
                    // We'll rely on the hash navigation to scroll
                  }}
                >
                  {section.title}
                </a>
              ))}
            </div>
          )}
        </nav>
      </aside>

      <main className="flex-1 p-6 overflow-auto">
        {showPlan ? (
          <PlatformPlan />
        ) : (
          <Routes>
            <Route path="/" element={<AdminOverview />} />
            <Route path="/posts" element={<PostModeration />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/settings" element={<AdminSettings />} />
          </Routes>
        )}
      </main>
    </div>
  );
}

export function AdminOverview() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Admin Overview</h1>
      <p>Welcome to the admin overview. Use the sidebar to manage posts, users, reports, settings, and view the platform plan.</p>
    </div>
  );
}

export function PostModeration() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Post Moderation</h1>
      <p>Here you can review, approve, or remove user-generated content.</p>
    </div>
  );
}

export function UserManagement() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">User Management</h1>
      <p>Manage user roles, permissions, and handle any necessary suspensions or bans.</p>
    </div>
  );
}

export function Reports() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Reports</h1>
      <p>View and address user-generated reports about content or behavior.</p>
    </div>
  );
}

export function AdminSettings() {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Admin Settings</h1>
      <p>Adjust platform-wide settings, toggle features, and manage configurations.</p>
    </div>
  );
}

// Define these outside so we can use them in both places
const monthSections = [
  { id: "month-1-3", title: "Months 1-3: Foundations" },
  { id: "month-4-5", title: "Months 4-5: Auth & Roles" },
  { id: "month-6-7", title: "Months 6-7: Database & Schema" },
  { id: "month-8-10", title: "Months 8-10: Marketplace & Payments" },
  { id: "month-11-12", title: "Months 11-12: Admin Portal" },
  { id: "month-13", title: "Month 13: Messaging & Realtime" },
  { id: "month-14-15", title: "Months 14-15: Content & Media" },
  { id: "month-16-17", title: "Months 16-17: Delivery & Regions" },
  { id: "month-18", title: "Month 18: Analytics & Personalization" },
  { id: "month-19", title: "Month 19: Security & Backup" },
  { id: "month-20-21", title: "Months 20-21: Final Testing & Launch Prep" }
];

function PlatformPlan() {
  const [view, setView] = useState<"monthly" | "weekly">("monthly");

  const monthlyTasks = [
    { month: "Months 1-3", description: "Foundations, learning, basic auth." },
    { month: "Months 4-5", description: "Detailed auth & role setups." },
    { month: "Months 6-7", description: "Database structure and rules." },
    { month: "Months 8-10", description: "Marketplace & payments." },
    { month: "Months 11-12", description: "Admin portal & moderation tools." },
    { month: "Month 13", description: "Messaging & realtime updates." },
    { month: "Months 14-15", description: "Content creation & media handling." },
    { month: "Months 16-17", description: "Delivery routing & region-based content." },
    { month: "Month 18", description: "Analytics & personalization." },
    { month: "Month 19", description: "Security hardening, backups, 'safe mode'." },
    { month: "Months 20-21", description: "Final testing, performance tuning, launch prep." }
  ];




  const weeklyTasks = Array.from({ length: 90 }, (_, i) => ({
    week: i + 1,
    description: `Week ${i + 1}: Continue planned tasks, refine features, fix bugs, and ensure component tests pass.`
  }));

  const [monthlyCompletion, setMonthlyCompletion] = useState(() => Array(monthlyTasks.length).fill(false));
  const [weeklyCompletion, setWeeklyCompletion] = useState(() => Array(weeklyTasks.length).fill(false));

  const toggleMonthlyTask = (index: number) => {
    const updated = [...monthlyCompletion];
    updated[index] = !updated[index];
    setMonthlyCompletion(updated);
  };

  const toggleWeeklyTask = (index: number) => {
    const updated = [...weeklyCompletion];
    updated[index] = !updated[index];
    setWeeklyCompletion(updated);
  };

  // Detailed weekly breakdown by phases
  const monthlyPhases = {
    "month-1-3": [
      "Weeks 1-2: Learn Next.js basics (L) - build sample pages.",
      "Weeks 3-4: Set up Node.js/Express API (I)",
      "Weeks 5-6: CI/CD & env setup (I)",
      "Weeks 7-8: Basic Next.js pages, tests (T)",
      "Weeks 9-10: Integrate simple auth with Clerk (I)",
      "Weeks 11-12: Review & refine (T)"
    ],
    "month-4-5": [
      "Weeks 13-14: Expand Clerk (roles) (I)",
      "Weeks 15-16: Secure token handling (I/T)",
      "Weeks 17-18: Validate role enforcement (T)",
      "Weeks 19-20: Front-end conditions for roles (T)"
    ],
    "month-6-7": [
      "Weeks 21-22: PostgreSQL schema (L/I)",
      "Weeks 23-24: Firestore integration & rules (I)",
      "Weeks 25-26: Data validation (I/T)",
      "Weeks 27-28: Database testing & refinements (T)"
    ],
    "month-8-10": [
      "Weeks 29-30: Stripe integration (L/I)",
      "Weeks 31-32: Product listing creation (I)",
      "Weeks 33-34: Buying/selling logic (I/T)",
      "Weeks 35-36: Subscription flows (T)",
      "Weeks 37-38: UI refinement (T)",
      "Weeks 39-40: Docs & finalize marketplace (T)"
    ],
    "month-11-12": [
      "Weeks 41-42: Protected admin routes (I)",
      "Weeks 43-44: Feature toggles, Hive moderation (I)",
      "Weeks 45-46: Appeals system & warnings (I/T)",
      "Weeks 47-48: Admin UI testing (T)"
    ],
    "month-13": [
      "Weeks 49-50: Integrate Comet chat (I)",
      "Weeks 51-52: Realtime notifications (I/T)"
    ],
    "month-14-15": [
      "Weeks 53-54: Article editor (I)",
      "Weeks 55-56: Video uploads to S3 (I)",
      "Weeks 57-58: Event scheduling (T)",
      "Weeks 59-60: UX polish (T)"
    ],
    "month-16-17": [
      "Weeks 61-62: Mapbox integration (L/I)",
      "Weeks 63-64: Regional content filtering (I)",
      "Weeks 65-66: Delivery routing logic (I/T)",
      "Weeks 67-68: Validate region-based features (T)"
    ],
    "month-18": [
      "Weeks 69-70: Analytics (GA, Mixpanel) (I)",
      "Weeks 71-72: AWS Personalize integration (I/T)"
    ],
    "month-19": [
      "Weeks 73-74: Cloudflare WAF, CSP (I)",
      "Weeks 75-76: Sentry, AWS Backup, safe mode (I/T)"
    ],
    "month-20-21": [
      "Weeks 77-78: End-to-end testing, mobile checks (T)",
      "Weeks 79-80: Accessibility & performance tuning (T)",
      "Weeks 81-82: Final bug fixes, deployment steps (T)",
      "Weeks 83-84: Launch & post-launch monitoring (I/T)"
    ]
  };

  // Completion state for monthlyPhases tasks
  // Structure: { [sectionId: string]: boolean[] }
  const initialPhaseCompletion = Object.fromEntries(
    monthSections.map((ms) => [ms.id, Array(monthlyPhases[ms.id].length).fill(false)])
  );
  const [phaseCompletion, setPhaseCompletion] = useState(initialPhaseCompletion);

  const togglePhaseTask = (sectionId: string, taskIndex: number) => {
    const updated = { ...phaseCompletion };
    updated[sectionId] = [...updated[sectionId]];
    updated[sectionId][taskIndex] = !updated[sectionId][taskIndex];
    setPhaseCompletion(updated);
  };

   return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-6">Platform Plan & Reasoning</h1>
      <p><strong>Concept & Goals:</strong> Home Growers Market is a platform for home growers to share educational content, interact in a marketplace, and engage through messaging, videos, and events. The goal is to provide scalable features for all users, with a Pro mode for advanced sellers and robust admin tools for moderation and content quality.</p>

      <h2 className="text-2xl font-semibold mt-8">Architecture & Reasoning</h2>
      <ul className="list-disc list-inside">
        <li><strong>Next.js (SSR/SPA):</strong> Ensures SEO benefits, fast initial load, and a smooth user experience.</li>
        <li><strong>Node.js & Express:</strong> Flexible API layer, easy integration with Next.js, room for customization as needs grow.</li>
        <li><strong>PostgreSQL & Firestore:</strong> PostgreSQL for structured data (users, products, permissions), Firestore for real-time events (chat, notifications).</li>
        <li><strong>Clerk:</strong> Secure, user-friendly authentication and authorization, role-based access control.</li>
        <li><strong>Stripe:</strong> Simplifies payments, subscriptions, and payouts, building user trust and revenue.</li>
        <li><strong>AWS S3 & AWS Backup:</strong> Reliable media storage and automated backups for resilience and quick recovery.</li>
        <li><strong>Comet & Agora:</strong> Real-time messaging and video calls to foster community engagement and special admin sessions.</li>
        <li><strong>Hive Moderation, Mapbox, AWS Personalize:</strong> Automated content moderation, location-based features, and personalized content recommendations .</li>
        <li><strong>Analytics & Security (Mixpanel, GA, Cloudflare WAF):</strong> Data-driven decisions and strong security measures protect users and the platform.</li>
        <li><strong>Admin Portal (Retool):</strong> Easy internal management of content, toggles, appeals, and safe mode during incidents.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8">Financial Breakdown</h2>
      <p>Each user pays $5/month ($60/year). Tax at 20% of revenue, costs about $7.30/user/year. Distribution:</p>
      <ul className="list-disc list-inside">
        <li><strong>Dev and City Pay:</strong> 20% of revenue ensures the core team and city involvement are compensated upfront.</li>
        <li><strong>Admin Team:</strong> 40% of profit after tax and costs.</li>
        <li><strong>Affiliates:</strong> 20% of profit after tax and costs.</li>
        <li>Remaining profit is retained by the company for reinvestment.</li>
      </ul>

      <table className="table-auto border-collapse border mt-8">
        <thead>
          <tr>
            <th className="border px-2 py-1">Users</th>
            <th className="border px-2 py-1">Annual Revenue</th>
            <th className="border px-2 py-1">Tax (20%)</th>
            <th className="border px-2 py-1">After Tax</th>
            <th className="border px-2 py-1">Costs</th>
            <th className="border px-2 py-1">Profit (After Tax & Costs)</th>
            <th className="border px-2 py-1">Admin (40% Profit)</th>
            <th className="border px-2 py-1">Affiliates (20% Profit)</th>
            <th className="border px-2 py-1">Remaining Profit</th>
            <th className="border px-2 py-1">Dev & City (20% Rev)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-2 py-1">1,000</td>
            <td className="border px-2 py-1">$60,000</td>
            <td className="border px-2 py-1">$12,000</td>
            <td className="border px-2 py-1">$48,000</td>
            <td className="border px-2 py-1">$7,300</td>
            <td className="border px-2 py-1">$40,700</td>
            <td className="border px-2 py-1">$16,280</td>
            <td className="border px-2 py-1">$8,140</td>
            <td className="border px-2 py-1">$16,280</td>
            <td className="border px-2 py-1">$12,000</td>
          </tr>
          <tr>
            <td className="border px-2 py-1">10,000</td>
            <td className="border px-2 py-1">$600,000</td>
            <td className="border px-2 py-1">$120,000</td>
            <td className="border px-2 py-1">$480,000</td>
            <td className="border px-2 py-1">$73,000</td>
            <td className="border px-2 py-1">$407,000</td>
            <td className="border px-2 py-1">$162,800</td>
            <td className="border px-2 py-1">$81,400</td>
            <td className="border px-2 py-1">$162,800</td>
            <td className="border px-2 py-1">$120,000</td>
          </tr>
          <tr>
            <td className="border px-2 py-1">50,000</td>
            <td className="border px-2 py-1">$3,000,000</td>
            <td className="border px-2 py-1">$600,000</td>
            <td className="border px-2 py-1">$2,400,000</td>
            <td className="border px-2 py-1">$365,000</td>
            <td className="border px-2 py-1">$2,035,000</td>
            <td className="border px-2 py-1">$814,000</td>
            <td className="border px-2 py-1">$407,000</td>
            <td className="border px-2 py-1">$814,000</td>
            <td className="border px-2 py-1">$600,000</td>
          </tr>
          <tr>
            <td className="border px-2 py-1">1,000,000</td>
            <td className="border px-2 py-1">$60,000,000</td>
            <td className="border px-2 py-1">$12,000,000</td>
            <td className="border px-2 py-1">$48,000,000</td>
            <td className="border px-2 py-1">$7,300,000</td>
            <td className="border px-2 py-1">$40,700,000</td>
            <td className="border px-2 py-1">$16,280,000</td>
            <td className="border px-2 py-1">$8,140,000</td>
            <td className="border px-2 py-1">$16,280,000</td>
            <td className="border px-2 py-1">$12,000,000</td>
          </tr>
        </tbody>
      </table>

      <h2 className="text-2xl font-semibold mt-8">Development Timeline (~21 Months)</h2>
      <p><strong>Context:</strong> 4 hours garunteed of code per days with two days off per week. 20 hours minimum a week of deep work.</p>
      <p><strong>Reasoning:</strong> 4 hours of coding, then 4-6 hours of networking, content creation, or moderation.</p>

      {/* Vertical Timeline */}
      {monthSections.map((ms) => (
        <div key={ms.id} id={ms.id} className="mb-8">
          <h3 className="text-lg font-bold mt-8 mb-2 border-b pb-2">{ms.title}</h3>
          <div className="border-l-4 border-emerald-600 pl-4 ml-2">
            {monthlyPhases[ms.id].map((weekTask, i) => {
              const done = phaseCompletion[ms.id][i];
              return (
                <div key={i} className="mb-2">
                  <div className="flex items-start space-x-2 cursor-pointer" onClick={() => togglePhaseTask(ms.id, i)}>
                    <div
                      className={`h-4 w-4 rounded-full mt-1 ${done ? 'bg-emerald-600' : 'bg-emerald-300'}`}
                      title="Click to mark this week as done"
                    ></div>
                    <p className={done ? 'line-through text-gray-600' : ''}>{weekTask}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
