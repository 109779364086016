import React from 'react';
import { motion } from 'framer-motion';
import { Heart, Gift, Users } from 'lucide-react';

export function Contribute() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto space-y-8"
    >
      <section className="text-center space-y-4">
        <Heart className="h-12 w-12 text-green-600 mx-auto" />
        <h1 className="text-3xl font-bold">Support the Community</h1>
        <p className="text-gray-600 max-w-3xl mx-auto">
          <span>
            Build a sustainable future by contributing to our community of local
            farmers, gardeners, and eco-conscious individuals.
          </span>
        </p>
      </section>

      <section className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">Make a Contribution</h2>
        <div className="space-y-6">
          <div className="flex items-start space-x-4">
            <Gift className="h-6 w-6 text-green-600 mt-1" />
            <div>
              <h3 className="text-lg font-semibold">Choose your member!</h3>
              <p className="text-gray-600">
                With an HGM membership you contribute one dollar to an
                indivigual who brought you to the community, or a member or
                orginization of your choosing.
              </p>
              <ul className="mt-2 space-y-2">
                <li className="flex items-center space-x-2">
                  <span className="text-green-600">•</span>
                  <span>
                    {' '}
                    Choose to support a local member starting up thier first
                    food forest{' '}
                  </span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-green-600">•</span>
                  <span>Contribute to a community garden effort</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-green-600">•</span>
                  <span>
                    Like anything else in this life your contributions are your
                    power.
                  </span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-green-600">•</span>
                  <span>Thank you for empowering others!</span>
                </li>
              </ul>
            </div>
          </div>

          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Contribution choice
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="15"
                  className="w-full px-3 py-2 border rounded-md focus:ring-green-500 focus:border-green-500"
                  placeholder="Search users and groups"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Select Your Gift
              </label>
              <select className="mt-1 w-full px-3 py-2 border rounded-md focus:ring-green-500 focus:border-green-500">
                <option>Organic Cotton T-shirt</option>
                <option>Recycled Material Hat</option>
                <option>Eco-friendly Sticker Pack</option>
              </select>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="showName"
                className="rounded text-green-600"
              />
              <label htmlFor="showName" className="ml-2 text-sm text-gray-600">
                Include my name in the contributors list
              </label>
            </div>

            <button className="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700">
              Make Contribution
            </button>
          </form>
        </div>
      </section>

      <section className="bg-white p-8 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold">Our Contributors</h2>
          <Users className="h-6 w-6 text-green-600" />
        </div>
        <div className="space-y-4">
          <p className="text-gray-600">
            Thank you to all our amazing contributors who help make our mission
            possible!
          </p>
          <div className="grid grid-cols-3 gap-4">
            <div className="text-center p-2 bg-green-50 rounded">John D.</div>
            <div className="text-center p-2 bg-green-50 rounded">Sarah M.</div>
            <div className="text-center p-2 bg-green-50 rounded">Anonymous</div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
