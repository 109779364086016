import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, Filter, Grid, List, MapPin, ShoppingCart } from 'lucide-react';

type Location = {
  latitude: number;
  longitude: number;
  address: string;
};

type ListingType = {
  id: string;
  title: string;
  price: number;
  image: string;
  seller: string;
  location: Location;
  category: string;
  distance?: number;
};

const MOCK_LISTINGS: ListingType[] = [
  {
    id: '101',
    title: 'Farm Fresh Free-Range Eggs',
    price: 6.99,
    image:
      'https://images.unsplash.com/photo-1543363136-2e5d2a33b302?auto=format&fit=crop&q=80&w=400',
    seller: 'Happy Hens Farm',
    location: {
      latitude: 36.1627,
      longitude: -86.7816,
      address: 'Nashville, TN',
    },
    category: 'Dairy & Eggs',
  },
  {
    id: '102',
    title: 'Organic Blueberries (1 lb)',
    price: 4.49,
    image:
      'https://images.unsplash.com/photo-1583257810523-370a6b009e6c?auto=format&fit=crop&q=80&w=400',
    seller: 'Berry Patch Farms',
    location: {
      latitude: 41.8781,
      longitude: -87.6298,
      address: 'Chicago, IL',
    },
    category: 'Fruit',
  },
  {
    id: '103',
    title: 'Handmade Beeswax Candles',
    price: 14.99,
    image:
      'https://images.unsplash.com/photo-1609447164324-fdc3436f0e44?auto=format&fit=crop&q=80&w=400',
    seller: 'Sunny Hive Co.',
    location: {
      latitude: 34.0522,
      longitude: -118.2437,
      address: 'Los Angeles, CA',
    },
    category: 'Crafts',
  },
  {
    id: '104',
    title: 'Raw Clover Honey (16 oz)',
    price: 12.99,
    image:
      'https://images.unsplash.com/photo-1553621042-f6e147245754?auto=format&fit=crop&q=80&w=400',
    seller: 'Golden Hive Honey',
    location: {
      latitude: 40.7128,
      longitude: -74.0060,
      address: 'New York, NY',
    },
    category: 'Pantry',
  },
  {
    id: '105',
    title: 'Fresh Basil Plant',
    price: 7.99,
    image:
      'https://images.unsplash.com/photo-1560185127-6a508d6c4b57?auto=format&fit=crop&q=80&w=400',
    seller: 'Green Thumb Gardens',
    location: {
      latitude: 32.7157,
      longitude: -117.1611,
      address: 'San Diego, CA',
    },
    category: 'Plants',
  },
  {
    id: '106',
    title: 'Sweet Corn (5 ears)',
    price: 3.99,
    image:
      'https://images.unsplash.com/photo-1586259030186-dc9a9e29323e?auto=format&fit=crop&q=80&w=400',
    seller: 'Harvest Valley Farm',
    location: {
      latitude: 39.7392,
      longitude: -104.9903,
      address: 'Denver, CO',
    },
    category: 'Vegetables',
  },
  {
    id: '107',
    title: 'Gourmet Mushroom Growing Kit',
    price: 24.99,
    image:
      'https://images.unsplash.com/photo-1604917877937-65d94bbf179f?auto=format&fit=crop&q=80&w=400',
    seller: 'Urban Spore Co.',
    location: {
      latitude: 29.7604,
      longitude: -95.3698,
      address: 'Houston, TX',
    },
    category: 'Gardening',
  },
  {
    id: '108',
    title: 'Lavender Sachets (Set of 3)',
    price: 9.99,
    image:
      'https://images.unsplash.com/photo-1602013578725-5c03cf2a1f04?auto=format&fit=crop&q=80&w=400',
    seller: 'Calm Fields Farm',
    location: {
      latitude: 42.3601,
      longitude: -71.0589,
      address: 'Boston, MA',
    },
    category: 'Crafts',
  },
  {
    id: '109',
    title: 'Handwoven Market Basket',
    price: 29.99,
    image:
      'https://images.unsplash.com/photo-1582560147728-1607e27f63db?auto=format&fit=crop&q=80&w=400',
    seller: 'Rustic Weaves',
    location: {
      latitude: 37.7749,
      longitude: -122.4194,
      address: 'San Francisco, CA',
    },
    category: 'Home Goods',
  },
  {
    id: '110',
    title: 'Seasonal Flower Bouquet',
    price: 19.99,
    image:
      'https://images.unsplash.com/photo-1524856170177-dc9e50e556d6?auto=format&fit=crop&q=80&w=400',
    seller: 'Bloom Valley',
    location: {
      latitude: 44.9778,
      longitude: -93.2650,
      address: 'Minneapolis, MN',
    },
    category: 'Flowers',
  },
 
];

export function StoreHome() {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [listings, setListings] = useState<ListingType[]>([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: 'Current Location',
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation) {
      const listingsWithDistance = MOCK_LISTINGS.map((listing) => ({
        ...listing,
        distance: calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          listing.location.latitude,
          listing.location.longitude
        ),
      })).sort((a, b) => (a.distance || 0) - (b.distance || 0));

      setListings(listingsWithDistance);
    } else {
      setListings(MOCK_LISTINGS);
    }
  }, [userLocation]);

  function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    const R = 3959; // Earth's radius in miles
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(R * c);
  }

  function toRad(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1488459716781-31db52582fe9?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-emerald-950/90 via-stone-950/80 to-green-900/40" />
        'bg-gradient-to-r from-blue-900 to-indigo-900'
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">
           HGM Store |  Verified local directly to you.
            </h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Local sellers are varified by HGM, and certified to claim labels. Shop with confidance locally!
          
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search listings..."
                  className="w-full pl-10 pr-4 py-3 border-2 border-green-100/20 bg-white/10 backdrop-blur-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-white placeholder-green-100/70"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-3.5 h-5 w-5 text-green-100" />
              </div>
              <div className="flex gap-2">
                <button className="p-3 bg-white/10 backdrop-blur-sm rounded-lg border-2 border-green-100/20 text-white hover:bg-white/20 transition-colors">
                  <Filter className="h-5 w-5" />
                </button>
                <div className="flex border-2 border-green-100/20 rounded-lg overflow-hidden">
                  <button
                    className={`p-3 ${
                      viewMode === 'grid' ? 'bg-white/20' : 'bg-white/10'
                    } backdrop-blur-sm text-white hover:bg-white/20 transition-colors`}
                    onClick={() => setViewMode('grid')}
                  >
                    <Grid className="h-5 w-5" />
                  </button>
                  <button
                    className={`p-3 ${
                      viewMode === 'list' ? 'bg-white/20' : 'bg-white/10'
                    } backdrop-blur-sm text-white hover:bg-white/20 transition-colors`}
                    onClick={() => setViewMode('list')}
                  >
                    <List className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {listings.map((listing) => (
          <motion.div
            key={listing.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.02 }}
            className="bg-white rounded-xl shadow-md overflow-hidden"
          >
            <img
              src={listing.image}
              alt={listing.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{listing.title}</h3>
              <p className="text-green-600 font-bold mb-2">
                ${listing.price.toFixed(2)}
              </p>
              <div className="text-sm text-gray-600">
                <p>{listing.seller}</p>
                <div className="flex items-center space-x-1 mt-1">
                  <MapPin className="h-4 w-4" />
                  <span>{listing.location.address}</span>
                </div>
                {listing.distance !== undefined && (
                  <p className="mt-1 text-green-600">
                    {listing.distance} miles away
                  </p>
                )}
              </div>
              <div className="mt-4">
              <button
  className="flex items-center justify-center w-full bg-green-600 text-white py-3 rounded-xl hover:bg-green-700 gap-2"
>
  <ShoppingCart className="h-5 w-5" />
  add to cart
</button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}
