import React from 'react';
import { motion } from 'framer-motion';
import { Share2, Calendar, MessageSquare, ThumbsUp, MapPin } from 'lucide-react';

const MOCK_POSTS = [
  {
    id: 1,
    title: 'Weekly CSA Box Contents',
    author: 'LocalFarmer',
    image: 'https://images.unsplash.com/photo-1592419044706-39796d40f98c?auto=format&fit=crop&q=80&w=600',
    content: "This week's harvest includes fresh tomatoes, lettuce, and herbs...",
    likes: 67,
    comments: 23,
    tags: ['Weekly Share', 'Seasonal', 'Local']
  },
  // ... other posts
];

export function FarmShare() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Rest of your existing JSX */}
    </motion.div>
  );
}