import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Search, Leaf, Sprout, MessageSquare, Users, Filter, Flower2 } from 'lucide-react';

const DISCUSSION_CATEGORIES = [
  {
    title: 'Community',
    description: 'Connect with fellow growers',
    icon: Users,
    items: [
      { name: 'Job Board', path: '/discussions/jobs', description: 'Find or post gardening and farming jobs' },
      { name: 'Gardens', path: '/discussions/gardens', description: 'Community garden projects and initiatives' }
    ]
  },
  {
    title: 'Identification',
    description: 'Help identify plants and more',
    icon: Leaf,
    items: [
      { name: 'Plants', path: '/discussions/id/plants', description: 'Identify unknown plants' },
      { name: 'Trees', path: '/discussions/id/trees', description: 'Tree identification help' },
      { name: 'Flowers', path: '/discussions/id/flowers', description: 'Flower identification assistance' }
    ]
  },
  {
    title: 'Growing',
    description: 'Growing techniques and methods',
    icon: Sprout,
    items: [
      { name: 'Permaculture', path: '/discussions/grow/permaculture', description: 'Sustainable growing practices' },
      { name: 'Hydroponic', path: '/discussions/grow/hydroponic', description: 'Soilless growing systems' },
      { name: 'Indoor Farm', path: '/discussions/grow/indoor', description: 'Indoor growing setups' },
      { name: 'Bee Keeping', path: '/discussions/grow/bees', description: 'Beekeeping tips and discussions' }
    ]
  },
  {
    title: 'Living',
    description: 'Sustainable living practices',
    icon: Leaf,
    items: [
      { name: 'Foraging', path: '/discussions/live/foraging', description: 'Wild food identification and gathering' },
      { name: 'Zero Waste', path: '/discussions/live/zero-waste', description: 'Reducing waste in daily life' },
      { name: 'Compost', path: '/discussions/live/compost', description: 'Composting methods and tips' },
      { name: 'Cooking', path: '/discussions/live/cooking', description: 'Cooking with homegrown ingredients' },
      { name: 'Fermented', path: '/discussions/live/fermented', description: 'Fermentation and preservation' }
    ]
  },
  {
    title: 'CSA',
    description: 'Community Supported Agriculture',
    icon: Flower2,
    items: [
      { name: 'Farm Share', path: '/discussions/csa/farm-share', description: 'CSA programs and experiences' }
    ]
  }
];

export function DiscussionBoard() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCategories = DISCUSSION_CATEGORIES.filter(category =>
    category.items.some(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Hero Section */}
      <div className="relative overflow-hidden rounded-xl mb-8">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1523348837708-15d4a09cfac2?auto=format&fit=crop&q=80&w=2000')`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 to-green-800/80" />
        <div className="relative py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-4">Discussion Boards</h1>
            <p className="text-green-50 mb-6 max-w-2xl">
              Join our community discussions on sustainable living, gardening, and more
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search discussions..."
                  className="w-full pl-10 pr-4 py-3 border-2 border-green-100/20 bg-white/10 backdrop-blur-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-white placeholder-green-100/70"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-3.5 h-5 w-5 text-green-100" />
              </div>
              <button className="p-3 bg-white/10 backdrop-blur-sm rounded-lg border-2 border-green-100/20 text-white hover:bg-white/20 transition-colors">
                <Filter className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Categories Grid */}
      <div className="grid gap-6">
        {filteredCategories.map((category) => (
          <motion.div
            key={category.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <div className="p-6">
              <div className="flex items-center space-x-3 mb-4">
                <category.icon className="h-6 w-6 text-green-600" />
                <h2 className="text-xl font-semibold">{category.title}</h2>
              </div>
              <p className="text-gray-600 mb-6">{category.description}</p>
              
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {category.items.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className="p-4 rounded-lg border hover:border-green-500 hover:shadow-md transition-all"
                  >
                    <h3 className="font-medium text-green-700 mb-2">{item.name}</h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </Link>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}