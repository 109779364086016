import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ConversationList } from '../components/messages/ConversationList';
import { ChatArea } from '../components/messages/ChatArea';
import { useMessages } from '../hooks/useMessages';
import { useParams } from 'react-router-dom';

export function Messages() {
  const { conversationId } = useParams();
  const { messages, loading } = useMessages(conversationId);
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      // TODO: Implement send message functionality
      setNewMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-white rounded-lg shadow-md overflow-hidden h-[calc(100vh-12rem)]"
    >
      <div className="grid grid-cols-12 h-full">
        <ConversationList selectedId={conversationId || null} />
        <ChatArea
          messages={messages}
          loading={loading}
          newMessage={newMessage}
          onNewMessageChange={setNewMessage}
          onSendMessage={handleSendMessage}
        />
      </div>
    </motion.div>
  );
}