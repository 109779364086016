import React, { useState } from 'react';
import { motion } from 'framer-motion';
import beachy from '../../assets/homecali.jpeg';

const TermsContent = [
  {
    title: 'Community Standards',
    content: `This is a politics-free and hate-free platform. We are committed to maintaining a safe and inclusive community for all users. Any violation of these principles may result in the suspension or termination of your account without notice, refund, or appeal.`,
  },
  {
    title: 'Transaction Responsibility',
    content: `This platform does not facilitate or endorse off-platform transactions. You are solely responsible for ensuring compliance with all applicable local laws regarding trade, barter, and controlled substances.`,
  },
  {
    title: 'Age Requirement',
    content: `By using this platform, you confirm that you are at least 16 years old. If you are under the age of majority in your jurisdiction, you may need parental consent to use the platform in compliance with local laws.`,
  },
  {
    title: 'Compliance with Authorities',
    content: `This platform operates in full compliance with local, regional, and national authorities. Any activity or content on this platform may be subject to reporting to the appropriate authorities if legally requested.`,
  },
  {
    title: 'Privacy and Data Handling',
    content: `This platform respects your privacy and handles personal data in accordance with our Privacy Policy. However, we may disclose user information to authorities when legally required or in compliance with applicable laws.`,
  },
];

export function Signup() {
  const [currentStep, setCurrentStep] = useState(1);
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const handleNext = () => {
    if (currentStep < TermsContent.length) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setAgreementAccepted(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleRedirectToClerkSignUp = () => {
    window.location.href = 'https://accounts.hgm.life/sign-up';
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="min-h-screen flex items-center justify-center bg-cover bg-center py-12 px-4 sm:px-6 lg:px-8"
      style={{ backgroundImage: `url(${beachy})`, borderRadius: '1.5rem' }}
    >
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-80 p-8 rounded-3xl shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
            {agreementAccepted ? 'Ready to Sign Up' : 'User Agreement'}
          </h2>
        </div>

        {!agreementAccepted ? (
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-gray-900 text-center">
              Step {currentStep} of {TermsContent.length}
            </h3>
            <h4 className="text-lg font-semibold text-gray-800">{TermsContent[currentStep - 1].title}</h4>
            <p className="text-md text-gray-700 leading-relaxed">{TermsContent[currentStep - 1].content}</p>
            <div className="flex justify-between">
              <button
                type="button"
                className={`px-4 py-2 rounded-md text-white ${
                  currentStep === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                }`}
                onClick={handleBack}
                disabled={currentStep === 1}
              >
                Back
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                onClick={handleNext}
              >
                {currentStep === TermsContent.length ? 'Accept Terms' : 'Next'}
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-8">
            <button
              onClick={handleRedirectToClerkSignUp}
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Go to Sign-Up Page
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
}
